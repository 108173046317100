import { useAuth0 } from '@auth0/auth0-react';
import { data } from '../../../config/config';
import { useEffect } from 'react';

import {
  Image,
  Text,
  Center,
  Container,
  Button,
  Stack,
  Link,
} from '@chakra-ui/react';
import { PhoneIcon } from '@chakra-ui/icons';
import { useNavigate } from "react-router-dom";

export const LoginPage = () => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();
  
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/home",
      },
      authorizationParams: {
        prompt: "login",
      },
    });
  };
  useEffect(() => {
    if (isAuthenticated) { 
      navigate("/home");
    }
  }, [isAuthenticated]);
  return (
    <Center
      bgGradient={{
        sm: `linear(to-r, ${data.linearGradient[0]}, ${data.linearGradient[1]})`,
      }}
      height="100vh"
    >
      <Container
        maxW="md"
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={{ base: 'transparent', sm: 'bg-surface' }}
        boxShadow={{ base: 'none', sm: 'xl' }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <Stack spacing="8">
          <Stack spacing="6" align="center">
            <Image
              className="RepLoginImage"
              width="12rem"
              height="5rem"
              objectFit="contain"
              src={data.icon}
              alt={data.companyName}
            />
          </Stack>
          {!isAuthenticated && (
            <Button onClick={() => handleLogin()}>Login</Button>
          )}
          <Stack spacing="0.5" align="center">
            <Text fontSize="sm" color="muted">
              Having trouble logging in?
            </Text>
            <Link href={`${data.contactUri}`}>
              <Button
                variant="link"
                leftIcon={<PhoneIcon />}
                color={data.primaryColor}
                size="sm"
              >
                Contact us
              </Button>
            </Link>
          </Stack>
          <Text fontSize="xs" color="subtle" textAlign="center">
            By continuing, you acknowledge that you have read, understood, and
            agree to our terms and condition
          </Text>
        </Stack>
      </Container>
    </Center>
  );
};
