import { FunctionComponent, useEffect, useState } from 'react';
import { IPlace } from '@mentaport/supplement';
import { Flex, Input, Select } from '@chakra-ui/react';
import { getUsaStates } from '../../../../../data/usa-states';

export interface StateElementProps {
  place: IPlace;
  onChange: (value: IPlace) => void;
}

export const StateElement: FunctionComponent<StateElementProps> = props => {
  const [place, setPlace] = useState<IPlace>({
    countryCode: 'US',
    state: props.place.city ? '' : props.place.state,
    city: '',
  });
  useEffect(() => {
    props.onChange(place);
  }, [place]);

  const getStateOptions = () => {
    const states = getUsaStates();
    if (!states) {
      return <></>;
    }
    return states.map((state, index) => {
      if (!state) {
        return <></>;
      }
      return (
        <option selected={state === place.state} key={index} value={state}>
          {state}
        </option>
      );
    });
  };
  return (
    <Flex direction={'column'} gap={3}>
      <Flex direction={'column'} gap={1}>
        <strong>USA states</strong>
        <Select
          title={place.state}
          bgColor={'white'}
          placeholder="State Name"
          onChange={event => {
            setPlace({
              ...place,
              countryCode: 'US',
              state: event.currentTarget.value,
            });
          }}
        >
          {getStateOptions()}
        </Select>
      </Flex>
    </Flex>
  );
};
