import { ITime } from '@mentaport/supplement';
export const getFormattedDate = (date: Date) => {
  if (!date) {
    return;
  }
  const year = date.getFullYear();
  const _month = date.getMonth() + 1;
  const month = _month.toString().length === 1 ? `0${_month}` : _month;
  const _day = date.getDate();
  const day = _day.toString().length === 1 ? `0${_day}` : _day;
  const _hours = date.getHours();
  const hours = _hours.toString().length === 1 ? `0${_hours}` : _hours;
  const minutes = date.getMinutes();
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const getDates = (when: ITime | undefined) => {
  let result: string | undefined = undefined;
  if (when?.startTime) {
    result = `${when?.startTime?.toString()}`;
  }
  if (when?.endTime) {
    result = `${result} - ${when?.endTime?.toString()}`;
  }
  return result;
};
