import { AccessTypes, LocationTypes, TimeTypes } from '@mentaport/supplement';

export const getTimeType = (type: TimeTypes) => {
  switch (type) {
    case TimeTypes.None:
      return 'Any time';
    case TimeTypes.StartTime:
      return 'Start time';
    case TimeTypes.TimeWindow:
      return 'Time Window';
    default:
      break;
  }
};

export const getLocationType = (type: LocationTypes) => {
  switch (type) {
    case LocationTypes.None:
      return 'Anywhere';
    default: {
      const temType = type.toString();
      return temType.charAt(0).toUpperCase() + temType.slice(1);
    }
  }
};

export const getAccessType = (type: AccessTypes) => {
  switch (type) {
    default: {
      const temType = type.toString();
      return temType.charAt(0).toUpperCase() + temType.slice(1);
    }
  }
};
