import {
  Button,
  ButtonProps,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

interface NavButtonProps extends ButtonProps {
  icon: IconType;
  label: string;
  active?: boolean;
  isButton: boolean;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, active, isButton, ...buttonProps } = props;
  const isDesktop = useBreakpointValue({ base: false, xl: true });

  if (!isButton) {
    return (
      <Button variant={''} justifyContent={isDesktop ? 'start' : 'center'}>
        <HStack spacing="3">
          <Icon as={icon} />
          {isDesktop && <Text>{label}</Text>}
        </HStack>
      </Button>
    );
  }
  return (
    <Button
      variant={active === false ? '' : 'black'}
      justifyContent={isDesktop ? 'start' : 'center'}
      {...buttonProps}
    >
      <HStack spacing="3">
        <Icon as={icon} />
        {isDesktop && <Text>{label}</Text>}
      </HStack>
    </Button>
  );
};
