import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  ResponsiveValue,
} from '@chakra-ui/react';
import { FunctionComponent, ReactNode, useState } from 'react';
import {
  IAccess,
  LocationTypes,
  ITime,
  TimeTypes,
  ILocation,
} from '@mentaport/supplement';

import { WhenSection } from '../../../Pages/ManageRulePage/components/WhenSection';
import { WhereSection } from '../../../Pages/ManageRulePage/components/WhereSection/WhereSection';
import { WhoSection } from '../../../Pages/ManageRulePage/components/WhoSections';
import { getLocationType } from '../../../helpers/mentaport-types-helper';
import { useDialog } from '../../../hooks/use-dialog';
import { MessageStatus } from '../../../services/dialog';
import { SideDrawerType } from '../../../data/side-drawer-types';

export interface BaseRuleSection {
  label: string;
}

export interface WhoRuleSection extends BaseRuleSection {
  access: IAccess;
}

export interface WhenRuleSection extends BaseRuleSection {
  time: ITime;
}

export interface WhereRuleSection extends BaseRuleSection {
  location: ILocation;
}

interface ContentRegistry {
  sideDrawerType: SideDrawerType;
  component: ReactNode;
}

export interface SideDrawerProps {
  sideDrawerType: SideDrawerType;
  sideBarContent?: IAccess | ITime | ILocation;
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (rule: BaseRuleSection) => void;
  locationType?: LocationTypes;
  size?:
    | ResponsiveValue<'sm' | 'md' | 'lg' | 'xl' | (string & {}) | 'xs' | 'full'>
    | undefined;
}

const isNotValidRule = (ruleSection: BaseRuleSection) => {
  return true;
};

const whenLabels = new Map<TimeTypes, string>([
  [TimeTypes.StartTime, 'START TIME'],
  [TimeTypes.None, 'ANYTIME'],
  [TimeTypes.TimeWindow, 'TIME WINDOW'],
]);

export const SideDrawer: FunctionComponent<SideDrawerProps> = props => {
  const [ruleSectionValue, SetRuleSectionValue] = useState<BaseRuleSection>();
  const dialog = useDialog();

  const handleConfirm = () => {
    if (!ruleSectionValue) {
      return;
    }
    if (!isNotValidRule(ruleSectionValue)) {
      dialog.notify(
        MessageStatus.Error,
        'Rule Validation',
        'The validation of the rule has failed. Please review all the fields'
      );
    }
    props.onConfirm?.(ruleSectionValue);
    props.onClose();
  };

  const contentRegistry: ContentRegistry[] = [
    {
      sideDrawerType: SideDrawerType.When,
      component: (
        <WhenSection
          selectedTime={props.sideBarContent as ITime}
          onChange={whenValue => {
            const whenRuleSection: WhenRuleSection = {
              time: whenValue,
              label: whenLabels.get(whenValue.type) ?? '',
            };
            SetRuleSectionValue(whenRuleSection);
          }}
        />
      ),
    },
    {
      sideDrawerType: SideDrawerType.Where,
      component: (
        <WhereSection
          selectedLocation={props.sideBarContent as ILocation}
          onChange={whereValue => {
            const whereRuleSection: WhereRuleSection = {
              location: whereValue,
              label: getLocationType(whereValue.type),
            };
            SetRuleSectionValue(whereRuleSection);
          }}
        />
      ),
    },
    {
      sideDrawerType: SideDrawerType.Who,
      component: (
        <WhoSection
          customElements={(props.sideBarContent as IAccess)?.whitelist ?? []}
          onChange={whoValue => {
            const whoRuleSection: WhoRuleSection = {
              access: whoValue,
              label: whoValue.type.toUpperCase(),
            };
            SetRuleSectionValue(whoRuleSection);
          }}
        />
      ),
    },
  ];

  return (
    <Drawer
      isOpen={props.isOpen}
      placement="right"
      onClose={props.onClose}
      size={props.size || 'md'}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={'brand_blue.50'}>
        <DrawerCloseButton />
        <DrawerHeader></DrawerHeader>
        <DrawerBody>
          {
            contentRegistry.find(
              element => element.sideDrawerType === props.sideDrawerType
            )?.component
          }
        </DrawerBody>
        <DrawerFooter gap={2}>
          <Button variant={'dark'} onClick={handleConfirm}>
            Confirm
          </Button>
          <Button variant={'dark'} onClick={props.onClose}>
            Cancel
          </Button>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
