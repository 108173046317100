import { useNavigate, useParams } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  Flex,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Routes } from '../../data/routes';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';
import {
  IResults,
  ContractEnvironment,
  ICertificateContract,
  ContractStatus,
} from '@mentaport/supplement';
import {
  ContractEnvironmentContent,
  ContractListComponent,
} from '../../components/commons/ContractListComponent/ContractListComponent';
import { DrawerDialog } from '../../components/commons/DrawerDialog/DrawerDialog';
import { getPlaceHolder } from '../../helpers/list-helper';
import { useState } from 'react';
import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';
import { useMentaport } from '../../hooks/use-mentaport';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import _ from 'lodash';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import "./UserContractsPage.scss";


export const UserContractsPage = () => {
  let { id, email } = useParams();

  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const mentaport = useMentaport();
  const dialog = useDialog();
  // const [contractAddress, setContractAddress] = useState<string>('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activationLoading, setActivationLoading] = useState(false);
  const [lastClickedContract, setLastClickedContract] = useState<ICertificateContract>();

  const [contractsResponse, contractsResponseLoading, setContracts] =
    useDataFetcher<IResults<ICertificateContract[]>>({
      serviceCall: () => {
        return mentaport?.getContracts(id!);
      },
      dependencies: [mentaport],
      conditionForExecution: mentaport !== undefined,
      onError(error) {
        dialog.notify(
          MessageStatus.Error,
          'Contracts',
          'There was an error fetching the contracts.'
        );
        console.error(error);
      },
    });

  const handleCreateContract = async () => {
    trackEvent(AnalyticEvent.NavigationToCreateNewContract);
    navigate(`/contracts/new/${id}`);
  };

  const handleActivate = async (contract: ICertificateContract) => {
    setLastClickedContract(contract);
    // if mezzanine:
    if (contract.environment === ContractEnvironment.Mezzanine) {
      await handleMezzanineActivate(contract);
      return;
    }
    onOpen();
  };

  const handleMezzanineActivate = async (contract: ICertificateContract) => {
    try {
      // console.log("contract", contract)
      const result = await mentaport?.activateMezzanineContract(contract, contract.customerId);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Active);
        trackEvent(AnalyticEvent.ActivateContractUsers, contract);
        dialog.notify(
          MessageStatus.Success,
          'Mezzanine Contracts',
          'The contract has been activated'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Mezzanine Contracts',
          'There was an issue activating the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Mezzanine Contracts',
        'There was an error activating the contract'
      );
    }
  };
  const handlePauseContract = async (contract: ICertificateContract) => {
    try {
      const result = await mentaport?.pauseContract(contract, id!);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Paused);
        trackEvent(AnalyticEvent.PauseContract, contract)
        dialog.notify(
          MessageStatus.Success,
          'Contracts',
          'The contract has been paused'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Contracts',
          'There was an issue pausing the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Contracts',
        'There was an error pausing the contract'
      );
    }
  };

  const handleResumeContract = async (contract: ICertificateContract) => {
    try {
      const result = await mentaport?.resumeContract(contract, id!);
      if (result?.status === true) {
        updateContractStatus(contract, ContractStatus.Active);
        trackEvent(AnalyticEvent.ResumeContract, contract)
        dialog.notify(
          MessageStatus.Success,
          'Contracts',
          'The contract has been resumed'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Contracts',
          'There was an issue resuming the contract'
        );
      }
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Contracts',
        'There was an error resuming the contract'
      );
    }
  };

  const updateContractStatus = (
    contract: ICertificateContract,
    newStatus: ContractStatus
  ) => {
    trackEvent(AnalyticEvent.UpdateContract, contract);
    if (contractsResponse?.data === undefined) {
      return;
    }
    const contractsData = contractsResponse?.data;
    const index = _.findIndex(
      contractsData,
      x => x.contractId === contract.contractId
    );
    if (index === -1) {
      return;
    }
    contractsData[index].status = newStatus;
    setContracts({
      status: true,
      statusCode: 205,
      message: 'Contract Activated ',
      data: contractsData,
    });
  };

  const getManagementButtons = () => {
    return [
      <Button
        marginLeft={10}
        variant="pink"
        size="sm"
        onClick={() => {
          handleCreateContract();
        }}
      >
        Create Contract
      </Button>,
    ];
  };

  const renderSideDrawer = () => {
    return (
      <DrawerDialog
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        cancelTitle={`Cancel. I'll activate this contract later`}
        title={'Type of Activation'}
      >
        <Flex direction="column">
          <Card className="activation-option" gap={2} padding={2}>
            <Badge colorScheme="green">Recommended</Badge>
            <Text fontSize="lg">On Certificate Registry</Text>
            <Text fontSize="m">
              Activate directly in Mentaport Infrastructure
            </Text>
            <Button variant="mint" onClick={handleActivateOnRegistry}>
              Activate
            </Button>
          </Card>
          {/* 
          <Flex
            margin="2rem"
            alignContent="center"
            justifyItems="center"
            alignItems="center"
            justifyContent="center"
          >
         <Text fontSize="lg">OR</Text>
          </Flex>
          <Card className="activation-option" gap={2} padding={2}>
            <Badge colorScheme="green">Advanced</Badge>
            <Text fontSize="lg">On Existing contract</Text>
            <Text fontSize="m">
              Activate contract in a contract you already own in the blockchain
            </Text>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <Icon as={GiMoebiusTriangle} color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Contract Address"
                value={contractAddress}
                onChange={(event: any) =>
                  setContractAddress(event.target.value)
                }
              />
            </InputGroup> */}
          {/* <Button
              variant="mint"
              disabled={contractAddress.length === 0}
              opacity={contractAddress.length === 0 ? 0.5 : 1}
              onClick={handleActivateOnAlreadyDeployedContract}
            >
              Activate
            </Button> */}
          {/* </Card> */}

        </Flex>
      </DrawerDialog>
    );
  };


  const handleActivateOnRegistry = async () => {
    try {
      setActivationLoading(true);
      if (lastClickedContract === undefined) {
        return;
      }
      onClose();
      console.log('handleActivateOnRegistry', lastClickedContract, id!)
      const result = await mentaport?.activateCertificateContract(lastClickedContract, id!);
      if (result?.status === true) {
        updateContractStatus(lastClickedContract, ContractStatus.Active);
        trackEvent(AnalyticEvent.ActivateContract, lastClickedContract);
        dialog.notify(
          MessageStatus.Success,
          'Blockchain Contracts',
          'The contract has been activated'
        );
      } else {
        dialog.notify(
          MessageStatus.Error,
          'Blockchain Contracts',
          'There was an issue activating the contract'
        );
      }
    } catch (error: any) {
      const errorMessage = error?.data?.message ?? "Error in backend."
      dialog.notify(
        MessageStatus.Error,
        'Blockchain Contracts',
        errorMessage
      );
    } finally {
      setActivationLoading(false);
    }
  };

  const getData = (): ContractEnvironmentContent[] => {
    const groups = _.groupBy(contractsResponse?.data ?? [], x => x.environment);
    return [
      {
        contracts: groups[ContractEnvironment.Mainnet] ?? [],
        environmentType: ContractEnvironment.Mainnet,
      },
      {
        contracts: groups[ContractEnvironment.Testnet] ?? [],
        environmentType: ContractEnvironment.Testnet,
      },
      {
        contracts: groups[ContractEnvironment.Mezzanine] ?? [],
        environmentType: ContractEnvironment.Mezzanine,
      },
      {
        contracts: groups[ContractEnvironment.Localhost] ?? [],
        environmentType: ContractEnvironment.Localhost,
      },
    ];
  };

  return (
    <ManagementPage
      title={`Contracts - ${email}`}
      buttons={getManagementButtons()}
      path={`users/contracts/${id}`}
      hideBackButton={true}
      isLoading={contractsResponseLoading}
      placeholder={getPlaceHolder()}
      breadcrumbs={[]}
      previousLocation={'users'}
    >
      {renderSideDrawer()}
      <div className='UserContractsPage'>
      <ContractListComponent
        managementPageProps={{
          title: ``,
          path: '',
          hideBackButton: true,
          isLoading: contractsResponseLoading,
          placeholder: getPlaceHolder(),
          breadcrumbs: [],
          previousLocation: Routes.Home,
        }}
        complexActivationLoading={activationLoading || isOpen}
        environments={getData()}
        onEditContract={(contract: ICertificateContract) => {
          trackEvent(AnalyticEvent.NavigationtoEditContract, contract);
          navigate(`/contracts/edit/${contract.contractId}/${contract.customerId}`);
        }}
        onPauseContract={(contract: ICertificateContract): Promise<void> => {
          return handlePauseContract(contract);
        }}
        onResumeContract={(contract: ICertificateContract): Promise<void> => {
          return handleResumeContract(contract);
        }}
        onActivateContract={(contract: ICertificateContract): Promise<void> => {
          return handleActivate(contract);
        }}
        onManageContractRules={(
          contractId: string,
          contractName: string
        ): void => {
          trackEvent(AnalyticEvent.NavigationToManageContractRules, { contractId, contractName });
          navigate(`/contracts/${contractId}/${contractName}/rules`);
        }}
        onShowContractAnalytics={(
          contractId: string,
          contractName: string
        ): void => {
          trackEvent(AnalyticEvent.NavigationToContractAnalytics, { contractId, contractName });
          navigate(
            `/contracts/${contractId}/${contractName}/analytics`
          );
        }}
      />
      </div>
    </ManagementPage>
  )
};


