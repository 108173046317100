import { Box, Flex, Input, InputGroup, InputLeftAddon } from '@chakra-ui/react';
import { FunctionComponent, useEffect, useState } from 'react';
import { IGeoPoint } from '@mentaport/supplement';

export interface RadiusElementProps {
  selectedCoordinates: IGeoPoint;
  onChange: (whereValue: IGeoPoint) => void;
}

export const RadiusElement: FunctionComponent<RadiusElementProps> = props => {
  const [mainCoordinates, setMainCoordinates] = useState<IGeoPoint>(
    props.selectedCoordinates
  );

  useEffect(() => {
    props.onChange?.(mainCoordinates);
  }, [mainCoordinates]);

  return (
    <Flex className="LatLong" flexDirection={'column'} gap={5}>
      <strong>LATITUDE/LONGITUDE</strong>
      <Box className="LatitudeEntry">
        <InputGroup size="md">
          <InputLeftAddon width={'6rem'} children="Latitude" />
          <Input
            value={mainCoordinates.latitude}
            onChange={event =>
              setMainCoordinates({
                ...mainCoordinates,
                latitude: event.currentTarget.valueAsNumber,
              })
            }
            bgColor={'white'}
            type={'number'}
          />
        </InputGroup>
      </Box>
      <Box className="LongitudeEntry">
        <InputGroup size="md">
          <InputLeftAddon width={'6rem'} children="Longitude" />
          <Input
            value={mainCoordinates.longitude}
            onChange={event =>
              setMainCoordinates({
                ...mainCoordinates,
                longitude: event.currentTarget.valueAsNumber,
              })
            }
            bgColor={'white'}
            type={'number'}
          />
        </InputGroup>
      </Box>
      <Box className="RadiusEntry">
        <InputGroup size="md">
          <InputLeftAddon width={'6rem'} children="Radius (m)" />
          <Input
            value={mainCoordinates.radius}
            onChange={event =>
              setMainCoordinates({
                ...mainCoordinates,
                radius: event.currentTarget.valueAsNumber,
              })
            }
            bgColor={'white'}
            type={'number'}
          />
        </InputGroup>
      </Box>
    </Flex>
  );
};
