import { Flex, Input, Select, Divider } from '@chakra-ui/react';
import { ITime, TimeTypes } from '@mentaport/supplement';
import { FunctionComponent, useEffect, useState } from 'react';

export interface WhenSectionProps {
  selectedTime: ITime;
  onChange: (whenValue: ITime) => void;
}

export const WhenSection: FunctionComponent<WhenSectionProps> = props => {
  const [timeTypeSelected, setTimeTypeSelected] = useState(
    props.selectedTime.type
  );
  const [time, setTime] = useState<ITime>(props.selectedTime);

  useEffect(() => {
    props.onChange?.(time);
  }, [time]);

  return (
    <Flex flexDirection={'column'} gap={5}>
      <strong>Time</strong>
      <Select
        bgColor={'white'}
        placeholder="Select Time"
        onChange={event => {
          setTimeTypeSelected(event.currentTarget.value as TimeTypes);
          setTime({
            ...time,
            type: event.currentTarget.value as TimeTypes,
            endTime: '',
            startTime: '',
          });
        }}
      >
        <option value={TimeTypes.StartTime}>Start Date</option>
        <option value={TimeTypes.TimeWindow}>Time Window</option>
      </Select>
      <Divider border={'0.5px solid gray'} orientation="horizontal" />
      {timeTypeSelected === TimeTypes.StartTime && (
        <Flex className="StartTimeInput" flexDirection={'column'} gap={5}>
          <strong className="Title">Pick Start Date</strong>
          <Input
            bgColor={'white'}
            type={'datetime-local'}
            value={time.startTime}
            onChange={event =>
              setTime({
                ...time,
                startTime: event.currentTarget.value,
              })
            }
          />
          Start Date Selected <strong>{time.startTime}</strong>
        </Flex>
      )}
      {timeTypeSelected === TimeTypes.TimeWindow && (
        <Flex className="TimeWindowInput" flexDirection={'column'} gap={5}>
          <strong className="Title">Pick Start of Time Window</strong>
          <Input
            bgColor={'white'}
            type={'datetime-local'}
            value={time.startTime}
            onChange={event =>
              setTime({
                ...time,
                startTime: event.currentTarget.value,
              })
            }
          />
          <strong className="Title">Pick End of Time Window</strong>
          <Input
            bgColor={'white'}
            type={'datetime-local'}
            value={time.endTime}
            onChange={event =>
              setTime({
                ...time,
                endTime: event.currentTarget.value,
              })
            }
          />
          Time Window Selected <strong>{time.startTime}</strong> -
          <strong>{time.endTime}</strong>
        </Flex>
      )}
    </Flex>
  );
};
