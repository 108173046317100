import { Divider, Flex, Select } from '@chakra-ui/react';
import { LocationTypes, ILocation } from '@mentaport/supplement';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { getLocationType } from '../../../../helpers/mentaport-types-helper';
import { CityElement } from './components/CityElement';
import { RadiusElement } from './components/RadiusElement';
import { CountryElement } from './components/CountryElement';
import { StateElement } from './components/StateElement';
import { PolygonElement } from './components/PolygonElement';

export interface WhereSectionProps {
  selectedLocation: ILocation;
  onChange: (whereValue: ILocation) => void;
}

export const WhereSection: FunctionComponent<WhereSectionProps> = props => {
  const [locationTypeSelected, setLocationTypeSelected] =
    useState<LocationTypes>(props.selectedLocation.type);
  const [location, setLocation] = useState<ILocation>(props.selectedLocation);

  const componentRegistry = new Map<LocationTypes, ReactNode>([
    [
      LocationTypes.City,
      <CityElement
        place={location.place ?? { countryCode: '' }}
        onChange={value => {
          setLocation({ ...location, place: value });
        }}
      />,
    ],
    [
      LocationTypes.Country,
      <CountryElement
        place={location.place ?? { countryCode: '' }}
        onChange={value => {
          setLocation({ ...location, place: value });
        }}
      />,
    ],
    [
      LocationTypes.Polygon,
      <PolygonElement
        polygons={location.polygonList ?? []}
        onChange={value => {
          setLocation({ ...location, polygonList: value });
        }}
      />,
    ],
    [
      LocationTypes.Radius,
      <RadiusElement
        selectedCoordinates={location.mainCoordinate}
        onChange={value => {
          setLocation({ ...location, mainCoordinate: value });
        }}
      />,
    ],
    [
      LocationTypes.State,
      <StateElement
        place={location.place ?? { countryCode: '' }}
        onChange={value => {
          setLocation({ ...location, place: value });
        }}
      />,
    ],
  ]);

  useEffect(() => {
    props.onChange?.(location);
  }, [location]);

  const getOptions = () => {
    return Object.values(LocationTypes).map((element, index) => {
      if (![LocationTypes.None].includes(element)) {
        return (
          <option key={index} value={element}>
            {getLocationType(element)}
          </option>
        );
      }
    });
  };

  const handleTypeChange = (type: LocationTypes) => {
    setLocationTypeSelected(type);
    setLocation({ ...location, type });
  };

  const getComponentForLocationType = () => {
    return componentRegistry.get(locationTypeSelected);
  };

  return (
    <Flex flexDirection={'column'} gap={5}>
      <Select
        bg={'white'}
        placeholder="Select a Location Type"
        onChange={element => {
          handleTypeChange(element.currentTarget.value as LocationTypes);
        }}
      >
        {getOptions()}
      </Select>
      <Divider border={'0.5px solid gray'} orientation="horizontal" />
      {locationTypeSelected &&
        locationTypeSelected !== LocationTypes.None &&
        getComponentForLocationType()}
    </Flex>
  );
};
