import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  Text,
  Image,
  Avatar,
  useBreakpointValue,
  Icon,
  Button,
} from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';
import { NavButton } from './NavButton';
import { data, NavbarItem } from '../../../config/config';
import { useLocation, useNavigate } from 'react-router-dom';
import { clearAppData } from '../../services/jotai-store-service';
import { useAuth0 } from "@auth0/auth0-react";
import { useAtom } from 'jotai';
import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';

export const Navbar = () => {
  const { user, logout } = useAuth0();
  const [, triggerClearAllData] = useAtom(clearAppData);

  const navigate = useNavigate();
  const pathArray = window.location.pathname.split('/');
  const isDesktop = useBreakpointValue({ base: false, xl: true });
  const location = useLocation();
  const { trackEvent } = useAnalytics();
  
  const handleLogout = () => {
    triggerClearAllData()
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const userIsAddingRules = (option: any) => {
    return (
      isOptionActive(option) &&
      (location.pathname.includes('/rules/new') ||
        location.pathname.includes('/contracts/blockchain/new') ||
        location.pathname.includes('/contracts/mezzanine/new'))
    );
  };

  const isOptionActive = (option: any) => {
    return !!option.slug && location.pathname.indexOf(option.slug) !== -1;
  };

  const onRouteClick = (option: NavbarItem) => {
    let eventName = AnalyticEvent.NavigationToHome
    switch (option.slug) {
      case 'contracts':
        eventName = AnalyticEvent.NavigationToContracts
        break;
      case 'users':
        eventName = AnalyticEvent.NavigationToUsers
        break;
      case 'contractAnalytics':
        eventName = AnalyticEvent.NavigationToContractAnalytics
        break;
      case 'certificateAnalytics':
        eventName = AnalyticEvent.NavigationToCertificateAnalytics
        break;
      case 'blockchainAdmin':
        eventName = AnalyticEvent.NavigationToBlockchainAdmin
        break;
      default:
        eventName = AnalyticEvent.NavigationToHome
        break;
    }
    
    trackEvent(eventName);
    return navigate(`/${option.slug}`);
  }

  const onGoToHome = () => {
    trackEvent(AnalyticEvent.NavigationToHome);
    return navigate('/home');
  }

  return (
    <Flex as="section" minH="100vh" bg="#f1f1f1">
      <Flex
        flex="1"
        bg="bg-surface"
        boxShadow="sm"
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '5', sm: '1.5' }}
        px={{ base: '4', sm: '6' }}
        w={isDesktop ? 'auto' : '90px'}
      >
       
        <Stack justify="space-between" spacing="1" width="100%">
          <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
            <Stack align="center">
              <Image
                width={isDesktop ? '10rem' : '8rem'}
                height={isDesktop ? '2.75rem' : '3.0rem'}
                cursor="pointer"
                style={{
                  objectFit: 'contain',
                }}
                src={isDesktop ? data.icon : data.smallIcon}
                alt={data.companyName}
                onClick={onGoToHome}
              />
            </Stack>
            <Stack spacing="1">
              {data.Navbar.map((option, index) => {
                return (
                  <NavButton
                    style={option.style}
                    key={index}
                    aria-current={
                      pathArray[1] === option.displayName ? 'page' : 'false'
                    }
                    backgroundColor={userIsAddingRules(option) ? 'pink' : ''}
                    isButton={!!option.slug}
                    active={isOptionActive(option)}
                    label={option.displayName}
                    icon={option.icon}
                    onClick={onRouteClick.bind(null, option)}
                  />
                );
              })}
            </Stack>
          </Stack>
          <Stack spacing={{ base: '5', sm: '6' }}>
            <HStack spacing="3">
              <Avatar
                boxSize="10"
                name={user?.name || ''}
                src={user?.picture || ''}
              />
              {isDesktop && (
                <Box>
                  <Text textAlign="left" fontWeight="medium" fontSize="sm">
                    {user?.name}
                  </Text>
                  <Text color="muted" fontSize="sm">
                    {user?.email}
                  </Text>
                </Box>
              )}
            </HStack>
            <Divider />
            <Button
              variant="black"
              marginBottom={5}
              onClick={() => {
                handleLogout();
              }}
            >
              <Icon
                margin={1}
                as={FiLogOut}
                boxSize="3"
                width={'1.25rem'}
                height={'1.25rem'}
              />
              {isDesktop ? 'Logout' : ''}
            </Button>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
