import { FunctionComponent, useEffect, useState } from 'react';
import { IPlace } from '@mentaport/supplement';
import { Flex, Select } from '@chakra-ui/react';
import { getCountryFromCode, getCountryList } from '../../../../../data/places';

export interface CountryElementProps {
  place: IPlace;
  onChange: (value: IPlace) => void;
}

export const CountryElement: FunctionComponent<CountryElementProps> = props => {
  const [place, setPlace] = useState<IPlace>({
    countryCode:
      props.place.city !== '' || props.place.state !== ''
        ? ''
        : props.place.countryCode,
    city: '',
    state: '',
  });
  const [country, setCountry] = useState<string>();

  useEffect(() => {
    if (!place.countryCode) {
      return;
    }
    const country = getCountryFromCode(props.place.countryCode);
    if (!country) {
      return;
    }

    setCountry(country.countryName);
    props.onChange(place);
  }, [place, props.place]);

  const getCountryOptions = () => {
    const countries = getCountryList();
    if (!countries) {
      return <></>;
    }
    return Object.values(countries).map((_country, index) => {
      if (!_country.countryName) {
        return <></>;
      }
      return (
        <option
          selected={country === _country.countryName}
          key={index}
          value={_country.countryCode}
        >
          {_country.countryName}
        </option>
      );
    });
  };

  return (
    <Flex direction={'column'} gap={3}>
      <Flex direction={'column'} gap={1}>
        <strong>Country</strong>
        <Select
          title={country}
          bgColor={'white'}
          placeholder="Country Name"
          onChange={event => {
            setPlace({ ...place, countryCode: event.currentTarget.value });
            setCountry(event.currentTarget.value);
          }}
        >
          {getCountryOptions()}
        </Select>
      </Flex>
    </Flex>
  );
};
