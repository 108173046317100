import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Input,
  Stack,
  Text,
} from '@chakra-ui/react';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getPlaceHolder } from '../../helpers/list-helper';
import {
  DatePicker,
  DatePickerValue,
} from '../../components/commons/DatePicker/DatePicker';
import { DataTable, TableColumn } from '../../components/commons/Table/Table';
import {
  LineChartData,
  LineGraph,
  TooltipPayload,
} from '../../components/commons/Charts/RechartsLine/RechartsLine';
import './CertificateAnalyticsPage.scss';
import { useMentaport } from '../../hooks/use-mentaport';
import {
  ICertAnalytics,
  ICertIdAnalytics,
  ICertTopMovers,
  IReportAnalytics,
  IWalletAnalytics,
} from '@mentaport/supplement';
import { isEmpty, isEqual } from 'lodash';

const COLORS = {
  green: '#5CC8BE',
  blue: '#357AF6',
  yellow: '#FFC092',
  orange: '#FFF092',
};

const LINE_IDS = {
  certificate: 'certificate',
  walletAddress: 'wallet',
  reported: 'reported',
  reportedWallet: 'reportedWallet',
};

export const CertificateAnalyticsPage = () => {
  const currentDate = new Date();
  const oneMonthAgo = new Date(currentDate);
  oneMonthAgo.setMonth(currentDate.getMonth() - 1);

  const [certificateId, setCertificateId] = useState<string>('');
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [showReportedCert, setShowReportedCert] = useState(false);
  const [showReportedWallet, setShowReportedWallet] = useState(false);
  const [dateRange, setDateRange] = useState<DatePickerValue>([
    oneMonthAgo,
    currentDate,
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const [certificateGraphAnalytics, setCertificateGraphAnalytics] = useState<
    ICertIdAnalytics[]
  >([]);
  const [walletAnalytics, setWalletAnalytics] = useState<IWalletAnalytics[]>(
    []
  );
  const [topMoverAnalytics, setTopMoverAnalytics] = useState<ICertTopMovers>();
  const [reportedWalletAnalytics, setReportedWalletAnalytics] = useState<
    IReportAnalytics[]
  >([]);
  const [reportedCertAnalytics, setReportedCertAnalytics] = useState<
    IReportAnalytics[]
  >([]);
  const [certificateAnalytics, setCertificateAnalytics] = useState<
    ICertAnalytics[]
  >([]);
  const mentaportService = useMentaport();

  const fetchGraphAnalytics = useCallback(async () => {
    const marshalledDateRange = Array.isArray(dateRange)
      ? dateRange
      : [dateRange, dateRange];
    const queryDateMin = (marshalledDateRange[0] || new Date()).toISOString();
    const queryDateMax = (marshalledDateRange[1] || new Date()).toISOString();
    const res = await mentaportService?.getCertIdWalletAnalytics(
      queryDateMin,
      queryDateMax,
      certificateId || 'na',
      walletAddress || 'na'
    );

    if (res?.data) {
      const newCertData = res.data.certId;
      const newWalletData = res.data.wallet;
      const newCertReportedData = res.data.reportCertId;
      const newWalletReportedData = res.data.reportWallet;

      if (!isEqual(newCertData, certificateGraphAnalytics))
        setCertificateGraphAnalytics(newCertData);
      if (!isEqual(newWalletData, walletAnalytics))
        setWalletAnalytics(newWalletData);
      if (!isEqual(reportedCertAnalytics, newCertReportedData))
        setReportedCertAnalytics(newCertReportedData);
      if (!isEqual(reportedWalletAnalytics, newWalletReportedData))
        setReportedWalletAnalytics(newWalletReportedData);
    }
  }, [
    mentaportService,
    walletAddress,
    certificateId,
    certificateGraphAnalytics,
    walletAnalytics,
    dateRange,
  ]);

  const fetchCertificateAnalytics = useCallback(async () => {
    if (!mentaportService) return;
    const res = await mentaportService?.getCertificatesAnalytics();

    if (!!res?.status && !isEmpty(res.data)) {
      const certificateData = res.data.certificates;
      const topMoversData = res.data.topMovers;

      if (!isEqual(certificateAnalytics, certificateData))
        setCertificateAnalytics(certificateData);
      if (!isEqual(topMoverAnalytics, topMoversData))
        setTopMoverAnalytics(topMoversData);
    }
  }, [
    mentaportService,
    topMoverAnalytics,
    certificateAnalytics,
    reportedCertAnalytics,
    reportedWalletAnalytics,
  ]);

  const onDateRangeChanged = useCallback(
    (newValue: DatePickerValue) => {
      setDateRange(newValue);
      fetchGraphAnalytics();
    },
    [fetchGraphAnalytics]
  );

  const onRefreshData = useCallback(async () => {
    const promises = [fetchGraphAnalytics(), fetchCertificateAnalytics()];
    setIsLoading(true);
    await Promise.all(promises);
    setIsLoading(false);
  }, [fetchGraphAnalytics, fetchCertificateAnalytics]);

  useEffect(() => {
    if (!!mentaportService) {
      onRefreshData();
    }
  }, [mentaportService]);

  const onChangeCertificateId = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setCertificateId(value);
    },
    [setCertificateId]
  );

  const onChangeWalletAddress = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setWalletAddress(value);
    },
    [setWalletAddress]
  );

  const onToggleShowReportedWallet = useCallback(() => {
    setShowReportedWallet(o => !o);
  }, [setShowReportedWallet]);

  const onToggleShowReportedCertificate = useCallback(() => {
    setShowReportedCert(o => !o);
  }, [setShowReportedCert]);

  const onUpdateGraph = useCallback(() => {
    fetchGraphAnalytics();
  }, [fetchGraphAnalytics]);

  const onResetGraph = useCallback(() => {
    setCertificateId('');
    setWalletAddress('');
    fetchGraphAnalytics();
  }, [setCertificateId, setWalletAddress, fetchGraphAnalytics]);

  const xAxisTickFormatter = useCallback((value: any): string => {
    const valueStr = `${value}`;
    switch (valueStr) {
      case '0':
        return 'Jan';
      case '1':
        return 'Feb';
      case '2':
        return 'Mar';
      case '3':
        return 'Apr';
      case '4':
        return 'May';
      case '5':
        return 'Jun';
      case '6':
        return 'Jul';
      case '7':
        return 'Aug';
      case '8':
        return 'Sep';
      case '9':
        return 'Oct';
      case '10':
        return 'Nov';
      case '11':
        return 'Dec';
      default:
        return valueStr;
    }
  }, []);

  const xAxisCertificateIdTooltipFormatter = useCallback((value: any) => {
    return `Certificates verified in ${xAxisTickFormatter(value)}:`;
  }, []);

  const xAxisWalletAddressTooltipFormatter = useCallback((value: any) => {
    return `Verified by wallet ${xAxisTickFormatter(value)}:`;
  }, []);

  const xAxisReportedCertTooltipFormatter = useCallback((value: any) => {
    return `Reported (cert) in ${xAxisTickFormatter(value)}:`;
  }, []);

  const xAxisReportedWalletTooltipFormatter = useCallback((value: any) => {
    return `Reported (wallet) in ${xAxisTickFormatter(value)}:`;
  }, []);

  const yAxisCertificateIdTooltipFormatter = useCallback((value: any) => {
    return value;
  }, []);

  const yAxisWalletAddressTooltipFormatter = useCallback((value: any) => {
    return value;
  }, []);

  const yAxisReportedCertTooltipFormatter = useCallback((value: any) => {
    return value;
  }, []);

  const yAxisReportedWalletTooltipFormatter = useCallback((value: any) => {
    return value;
  }, []);

  const CustomTooltipContent = useCallback((props: any) => {
    const { active, payload } = props;
    const payloads = payload as TooltipPayload[];

    if (!active || !payloads.length) return null;

    return (
      <Box className="CustomTooltip Dark">
        <Stack w={'100%'}>
          {payloads.map((payload, idx) => {
            const { color, payload: data, id } = payload;
            const metadata = data.metadata;
            const metadataArray: [string, string | number][] = Object.entries(
              metadata || {}
            ) as [string, string | number][];
            metadataArray.sort((a, b) => {
              const numA = a[1] as number;
              const numB = b[1] as number;
              return numB - numA;
            });

            let xAxisformatter = xAxisWalletAddressTooltipFormatter;
            switch (id) {
              case LINE_IDS.certificate:
                xAxisformatter = xAxisCertificateIdTooltipFormatter;
                break;
              case LINE_IDS.reported:
                xAxisformatter = xAxisReportedCertTooltipFormatter;
                break;
              case LINE_IDS.reportedWallet:
                xAxisformatter = xAxisReportedWalletTooltipFormatter;
                break;
            }

            let yAxisFormatter = yAxisWalletAddressTooltipFormatter;
            switch (id) {
              case LINE_IDS.certificate:
                yAxisFormatter = yAxisCertificateIdTooltipFormatter;
                break;
              case LINE_IDS.reported:
                yAxisFormatter = yAxisReportedCertTooltipFormatter;
                break;
              case LINE_IDS.reportedWallet:
                yAxisFormatter = yAxisReportedWalletTooltipFormatter;
                break;
            }

            return (
              <Stack key={idx}>
                <HStack alignItems={'flex-end'} gap={2}>
                  <Box className="TooltipTitle">
                    <Text color={color}>{xAxisformatter(data.x)}</Text>
                  </Box>
                  <Box className="TooltipValue">
                    <Text color={color}>{yAxisFormatter(data.y)}</Text>
                  </Box>
                </HStack>
                {!isEmpty(metadataArray) &&
                  metadataArray.slice(0, 2).map((entry, idx) => {
                    return (
                      <Box className="TooltipDescription">
                        <Text color={color}>
                          {`${idx + 1}: ${entry[0]} - ${entry[1]}`}
                        </Text>
                      </Box>
                    );
                  })}
              </Stack>
            );
          })}
        </Stack>
      </Box>
    );
  }, []);

  const lineGraphData: LineChartData[] = useMemo(() => {
    const lineData = [];
    if (!isEmpty(certificateGraphAnalytics?.[0]?.perMonth)) {
      lineData.push({
        color: COLORS.green,
        id: LINE_IDS.certificate,
        data: certificateGraphAnalytics?.[0]?.perMonth.map((entry, idx) => {
          return {
            x: entry.month,
            y: entry.count,
            metadata: entry.url,
          };
        }),
      });
    }

    if (!isEmpty(walletAnalytics?.[0]?.perMonth)) {
      lineData.push({
        color: COLORS.blue,
        id: LINE_IDS.walletAddress,
        data: walletAnalytics?.[0]?.perMonth.map((entry, idx) => {
          return {
            x: entry.month,
            y: entry.count,
            metadata: entry.url,
          };
        }),
      });
    }

    if (showReportedCert) {
      lineData.push({
        color: COLORS.yellow,
        id: LINE_IDS.reported,
        data: reportedCertAnalytics
          ?.map((entry, idx) => {
            return {
              x: entry.month,
              y: entry.count,
              metadata: entry.url,
            };
          })
          .sort((a, b) => a.x - b.x),
      });
    }

    if (showReportedWallet) {
      lineData.push({
        color: COLORS.orange,
        id: LINE_IDS.reportedWallet,
        data: reportedWalletAnalytics
          ?.map((entry, idx) => {
            return {
              x: entry.month,
              y: entry.count,
              metadata: entry.url,
            };
          })
          .sort((a, b) => a.x - b.x),
      });
    }

    return lineData;
  }, [
    showReportedCert,
    showReportedWallet,
    walletAnalytics,
    reportedCertAnalytics,
    reportedWalletAnalytics,
    certificateGraphAnalytics,
    walletAnalytics,
  ]);

  const certificatesColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (certificateAnalytics?.length) {
      Object.keys(certificateAnalytics[0]).forEach(key => {
        tableColumns.push({
          title: key,
          id: key,
        });
      });
    }

    return tableColumns;
  }, [certificateAnalytics]);

  const topMoversColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    tableColumns.push(
      {
        title: 'certificate ID',
        id: 'certId',
      },
      {
        title: 'wallet address',
        id: 'wallet',
      },
      {
        title: 'count',
        id: 'count',
      }
    );

    return tableColumns;
  }, [certificateAnalytics]);

  const topMoversData = useMemo(() => {
    const data: { certId?: string; wallet?: string; count: number }[] = [];

    if (Array.isArray(topMoverAnalytics?.certId)) {
      topMoverAnalytics?.certId.forEach(certId => {
        data.push({
          certId: certId[0],
          count: certId[1],
        });
      });
    }

    if (Array.isArray(topMoverAnalytics?.wallet)) {
      topMoverAnalytics?.wallet.forEach(wallet => {
        data.push({
          wallet: wallet[0],
          count: wallet[1],
        });
      });
    }

    return data;
  }, [topMoverAnalytics]);

  return (
    <ManagementPage
      title={'Certificate Analytics'}
      path={'certificateAnalytics'}
      isLoading={isLoading}
      previousLocation={''}
      placeholder={getPlaceHolder()}
    >
      <Stack w={'100%'} spacing={6} p={30}>
        <Flex alignItems={'flex-end'} justifyContent={'space-between'}>
          <Text fontSize={26} fontWeight={600}>
            Top Movers
          </Text>
          <HStack>
            <Button
              className="Button"
              onClick={onRefreshData}
              isLoading={isLoading}
            >
              <Text px={4}>Refresh</Text>
            </Button>
            <DatePicker onChange={onDateRangeChanged} value={dateRange} />
          </HStack>
        </Flex>
        <Grid w={'100%'} templateColumns={'repeat(2, 1fr)'} gap={6}>
          <GridItem colSpan={1}>
            <DataTable
              data={topMoversData}
              columns={topMoversColumns}
              height={410}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <Stack>
              <LineGraph
                height={260}
                data={lineGraphData}
                tooltipContent={<CustomTooltipContent />}
                xAxisTickFormatter={xAxisTickFormatter}
              />
              <Flex justifyContent={'flex-end'}>
                <HStack>
                  {!!reportedWalletAnalytics.length && (
                    <Button
                      className="Button Reported"
                      onClick={onToggleShowReportedWallet}
                    >
                      <Text fontSize={'0.8rem'} px={2} fontWeight={400}>{`${
                        showReportedWallet ? 'Hide' : 'Show'
                      } Reported (Wallet)`}</Text>
                    </Button>
                  )}
                  {!!reportedCertAnalytics && (
                    <Button
                      className="Button Reported"
                      onClick={onToggleShowReportedCertificate}
                    >
                      <Text fontSize={'0.8rem'} px={2} fontWeight={400}>{`${
                        showReportedCert ? 'Hide' : 'Show'
                      } Reported (Cert)`}</Text>
                    </Button>
                  )}
                </HStack>
              </Flex>
              <HStack alignItems={'flex-end'} gap={4}>
                <Stack>
                  <Text color={COLORS.green} fontWeight={600}>
                    CertificateId
                  </Text>
                  <Input
                    className="InputContainer"
                    borderRadius={'0 5px 5px 0'}
                    type={'text'}
                    value={certificateId}
                    onChange={onChangeCertificateId}
                  />
                </Stack>
                <Stack>
                  <Text color={COLORS.blue} fontWeight={600}>
                    Wallet Address
                  </Text>
                  <Input
                    className="InputContainer"
                    borderRadius={'0 5px 5px 0'}
                    type={'text'}
                    value={walletAddress}
                    onChange={onChangeWalletAddress}
                  />
                </Stack>
                <Button className="Button" onClick={onUpdateGraph}>
                  <Text px={4}>Show</Text>
                </Button>
                <Button className="Button Warning" onClick={onResetGraph}>
                  <Text px={4}>All/Reset</Text>
                </Button>
              </HStack>
            </Stack>
          </GridItem>
          <GridItem colSpan={2}>
            <DataTable
              title="Certificates"
              data={certificateAnalytics || []}
              columns={certificatesColumns}
            />
          </GridItem>
        </Grid>
      </Stack>
    </ManagementPage>
  );
};
