import { useCallback, useEffect, useMemo, useState } from 'react';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { getPlaceHolder } from '../../helpers/list-helper';
import {
  Button,
  Center,
  Checkbox,
  Flex,
  HStack,
  Image,
  Input,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import './BlockchainControlPage.scss';
import { DataTable, TableColumn } from '../../components/commons/Table/Table';
import React from 'react';
import { CustomCellRendererProps } from 'ag-grid-react';
import { useDashboardApi } from '../../hooks/use-dashboard-api';
import { AddContractArgs, Contract, DeployContractArgs, UpdateContractArgs } from '../../data/contact-responses';
import { ValueFormatterParams } from '@ag-grid-community/core';
import { LocalIcons } from '../../assets/icons/icons';
import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';

type SelectOption = {
  id: string;
  title: string;
};
type SelectData = {
  placeholder: string;
  options: SelectOption[];
};

const DeployTypes: SelectData = {
  placeholder: 'ContractType',
  options: [
    {
      id: 'certificates',
      title: 'Certificate',
    },
    {
      id: 'rewards',
      title: 'Rewards',
    },
  ],
};

const BlockChainTypes: SelectData = {
  placeholder: 'Blockchain',
  options: [
    {
      id: 'base',
      title: 'Base',
    },
    // {
    //   id: 'polygon',
    //   title: 'Polygon',
    // }
  ],
};

const EnvironmentTypes: SelectData = {
  placeholder: 'Environment',
  options: [
    {
      id: 'testnet',
      title: 'testnet',
    },
    {
      id: 'mainnet',
      title: 'mainnet',
    },
  ],
};

interface CustomContractCellRendererProps extends CustomCellRendererProps {
  onMakeMainClick: (data: Contract) => void;
  onPauseOrActivate: (data: Contract) => void;
  onArchive: (data: Contract) => void;
  sendingRequest?: "makeMain" | "pause" | "archive";
}

const CustomCellRenderer: React.FC<CustomContractCellRendererProps> = ({
  value,
  data,
  sendingRequest,
  onMakeMainClick,
  onPauseOrActivate,
  onArchive,
}) => {
  const parsedData = data as Contract;
  const isArchived = parsedData?.status === "Archive";
  const isMain = parsedData?.isMain.toString() === 'true';
  const isPaused = parsedData?.status === "Paused";
  const isUnavailable = !!sendingRequest || isArchived;

  const ActivateButtonText = sendingRequest === "pause" ? "Processing..." : isPaused ? "Activate" : "Pause";

  return (
    <HStack width={400} height={"100%"} alignItems={"center"}>
      <Button className={`Button Option ${isMain || isUnavailable ? "Disabled" : ""}`} onClick={onMakeMainClick.bind(this, parsedData)} disabled={isMain || isUnavailable}>
        {sendingRequest === "makeMain" ? "Processing..." : "Make Main"}
      </Button>
      <Button
        className={`Button Option ${isPaused ? 'Green' : 'Warning'} ${isUnavailable ? 'Disabled' : ''}`}
        onClick={onPauseOrActivate.bind(this, parsedData)}
        disabled={isUnavailable}
      >{ActivateButtonText}</Button>
      <Button
        className={`Button Option Red ${isUnavailable ? 'Disabled' : ''}`}
        onClick={onArchive.bind(this, parsedData)}
        disabled={isUnavailable}
      >
        {sendingRequest === "archive" ? "Processing..." : "Archive"}
      </Button>
    </HStack>
  );
};

export const BlockchainControlPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dashboardApi = useDashboardApi();
  const { trackEvent } = useAnalytics();

  /* Deploying Contract Variables */
  const [activateDeployedContract, setActivateDeployedContract] =
    useState(false);
  const [deployedContractIsMain, setDeployedContractIsMain] = useState(false);
  const [deployedContractType, setDeployedContractType] = useState<'certificates' | 'rewards'>();
  const [deployedcontractBlockchain, setDeployedContractBlockchain] =
    useState<string>('');
  const [deployedContractEnvironment, setDeployedContractEnvironment] =
    useState<'testnet' | 'mainnet'>();

  /* Add New contract Variables */
  const [newContractIsMain, setNewContractIsMain] = useState(false);
  const [newContractType, setNewContractType] = useState<'certificates' | 'rewards'>();
  const [newcontractBlockchain, setNewContractBlockchain] =
    useState<string>('');
  const [newContractEnvironment, setNewContractEnvironment] =
    useState<'testnet' | 'mainnet'>();
  const [newDeployContractAddress, setNewDeployContractAddress] = useState<string>('');
  const [newDeployContractUri, setNewDeployContractUri] = useState<string>('');
  const [newDeployContractName, setNewDeployContractName] = useState<string>('');
  const [newDeployContractSymbol, setNewDeployContractSymbol] = useState<string>('');
  const [newContractAddress, setNewContractAddress] = useState<string>('');
  const [newContractName, setNewContractName] = useState<string>('');
  const [certificateContracts, setCertificateContracts] = useState<Object[]>(
    []
  );
  const [rewardsContracts, setRewardsContracts] = useState<Object[]>([]);

  const [submittingPreexistingContract, setSubmittingPreexistingContract] = useState(false);
  const [submittingDeployContract, setSubmittingDeployContract] = useState(false);
  const [error, setError] = useState<string>('');
  // make the error message disappear after 5 seconds but the timer resets if the error message changes
  useEffect(() => {
    const timer = setTimeout(() => {
      setError('');
    }, 4 * 1_000);

    return () => clearTimeout(timer);
  }, [error]);

  const fetchContracts = useCallback(async () => {
    if (dashboardApi) {
      const [certificateData, rewardsData] = await Promise.allSettled([
        dashboardApi.getDashboardCertificates(),
        dashboardApi.getDashboardContracts(),
      ]);
      if (certificateData.status === 'fulfilled') {
        setCertificateContracts(certificateData.value?.map((contract: Contract) => {
          return {
            ...contract,
            isMain: contract.isMain.toString(),
          }
        }));
      }
      if (rewardsData.status === 'fulfilled') {
        setRewardsContracts(rewardsData.value?.map((contract: Contract) => {
          return {
            ...contract,
            isMain: contract.isMain.toString(),
          }
        }));
      }
    }
  }, [dashboardApi]);

  useEffect(() => {
    if (dashboardApi)
      fetchContracts();
  }, [dashboardApi])

  /* Deploy Contract Methods */
  const onChangeDeployedContractType = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setDeployedContractType(!event.target.value ? undefined : event.target.value as 'certificates' | 'rewards');
    },
    [setDeployedContractType]
  );

  const onChangeDeployedContractBlockChain = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setDeployedContractBlockchain(event.target.value);
    },
    [setDeployedContractType]
  );

  const onChangeDeployedContractEnvironment = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setDeployedContractEnvironment(!event.target.value ? undefined : event.target.value as 'testnet' | 'mainnet');
    },
    [setDeployedContractEnvironment]
  );

  const onChangeDeployedContractIsMain = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDeployedContractIsMain(event.target.checked);
    },
    [setDeployedContractIsMain]
  );

  const onChangeDeployedContractActivate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setActivateDeployedContract(event.target.checked);
    },
    [setDeployedContractIsMain]
  );

  const isValidLink = (link: string) => {
    if (!link) return false;
    return link.startsWith('http://') || link.startsWith('https://');
  }

  const isValidContractAddress = (address: string) => {
    if (!address) return false;
    return address.startsWith('0x');
  }

  const onSubmitDeployContract = useCallback(async () => {
    if (submittingDeployContract) {
      return;
    }
    if (!deployedContractEnvironment || !deployedcontractBlockchain || !deployedContractType) {
      setError('Please select an environment, blockchain, and contract type');
      return;
    }
    if (deployedContractType === 'certificates') {
      if (!newDeployContractName || !newDeployContractSymbol) {
        setError('Please enter a contract name and symbol');
        return;
      }
    } else {
      if (!newDeployContractUri || !isValidLink(newDeployContractUri)) {
        setError('Please enter a valid contract URI');
        return;
      }
    }

    // Deploy the contract
    const deployData: DeployContractArgs = {
      contractType: deployedContractType,
      blockchain: deployedcontractBlockchain,
      environment: deployedContractEnvironment,
      isMain: deployedContractIsMain,
      status: activateDeployedContract ? 'Active' : 'Paused',
      address: "",
    };

    if (deployedContractType === "certificates") {
      Object.assign(deployData, {
        name: newDeployContractName.trim(),
        symbol: newDeployContractSymbol.trim(),
      });
    } else {
      Object.assign(deployData, {
        rewardsContractUri: newDeployContractUri,
      });
    }

    console.log('deployData', deployData);
    try {
      setSubmittingDeployContract(true);
      const response = await dashboardApi?.deployContract(deployData);
      console.log('deploy response', response);
      if (!response.status) {
        throw new Error('Error deploying contract');
      }
      trackEvent(AnalyticEvent.DeployNewContract, {
        ...deployData
      });
      await fetchDataAndReset();
    } catch (error) {
      console.log('error', error);
      setError('Error deploying contract');
    } finally {
      setSubmittingDeployContract(false);
    }

  }, [
    deployedContractEnvironment,
    deployedcontractBlockchain,
    deployedContractType,
    newDeployContractName,
    newDeployContractSymbol,
    newDeployContractUri,
    newDeployContractAddress,
    deployedContractIsMain,
    activateDeployedContract,
    submittingDeployContract,
    setSubmittingDeployContract,
  ]);

  const onSubmitPreexistingContract = useCallback(async () => {
    if (submittingPreexistingContract) {
      return;
    }
    if (!newContractEnvironment || !newcontractBlockchain || !newContractType) {
      setError('Please select an environment, blockchain, and contract type');
      return;
    }

    if (!newContractAddress || !isValidContractAddress(newContractAddress) || !newContractName) {
      setError('Please enter a valid contract address and name');
      return;
    }

    // Add the contract
    const addData: AddContractArgs = {
      contractType: newContractType,
      blockchain: newcontractBlockchain,
      environment: newContractEnvironment,
      isMain: newContractIsMain,
      status: 'Active',
      address: newContractAddress,
      name: newContractName,
    };

    console.log('addData', addData);
    try {
      setSubmittingPreexistingContract(true);
      const response = await dashboardApi?.addContract(addData);
      console.log('add response', response);
      if (response.status) {
        trackEvent(AnalyticEvent.UploadNewContract, {
          ...addData
        });
        await fetchDataAndReset();
      } else {
        throw new Error('Error adding contract');
      }
    } catch (error) {
      console.log('error', error);
      setError('Error adding contract');
    } finally {
      setSubmittingPreexistingContract(false);
    }
  }, [
    newContractEnvironment,
    newcontractBlockchain,
    newContractType,
    newContractAddress,
    newContractIsMain,
    submittingPreexistingContract,
    setSubmittingPreexistingContract,
  ]);

  /* New Contract Methods */
  const onChangeNewContractType = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setNewContractType(!event.target.value ? undefined : event.target.value as 'certificates' | 'rewards');
    },
    [setNewContractType]
  );

  const onChangeNewContractBlockChain = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setNewContractBlockchain(event.target.value);
    },
    [setNewContractBlockchain]
  );

  const onChangeNewContractEnvironment = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      setNewContractEnvironment(!event.target.value ? undefined : event.target.value as 'testnet' | 'mainnet');
    },
    [setNewContractEnvironment]
  );

  const onChangeNewContractIsMain = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewContractIsMain(event.target.checked);
    },
    [setNewContractIsMain]
  );

  const onChangeDeployContractName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewDeployContractName(event.target.value.substring(0, 20));
    },
    [setNewDeployContractName]
  );

  const onChangeDeployContractSymbol = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewDeployContractSymbol(event.target.value.trim().slice(0, 4).toUpperCase());
    },
    [setNewDeployContractSymbol]
  );

  const onChangeNewDeployContractAddress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewDeployContractAddress(event.target.value);
    },
    [setNewDeployContractAddress]
  );

  const onChangeNewContractAddress = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewContractAddress(event.target.value);
    },
    [setNewContractAddress]
  );

  const onChangeNewContractName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewContractName(event.target.value);
    },
    [setNewContractName]
  );

  const onChangeDeployContractUri = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setNewDeployContractUri(event.target.value);
    },
    [setNewDeployContractUri]
  );

  const resetDeployContractVariables = useCallback(() => {
    setDeployedContractBlockchain('');
    setDeployedContractEnvironment(undefined);
    setDeployedContractIsMain(false);
    setDeployedContractType(undefined);
    setActivateDeployedContract(false);
    setNewDeployContractUri('');
    setNewDeployContractName('');
    setNewDeployContractSymbol('');
  }, []);

  const resetNewContractVariables = useCallback(() => {
    setNewContractAddress('');
    setNewContractBlockchain('');
    setNewContractName('');
    setNewContractEnvironment(undefined);
    setNewContractIsMain(false);
    setNewContractType(undefined);
  }, []);

  const fetchDataAndReset = useCallback(() => {
    resetDeployContractVariables();
    resetNewContractVariables();
    fetchContracts();
  }, [resetDeployContractVariables, resetNewContractVariables, fetchContracts]);

  const CustomCertificateContractCellRenderer: React.FC<
    CustomCellRendererProps
  > = props => {
    const [sendingRequest, setSendingRequest] = useState<"makeMain" | "pause" | "archive">();
    const onMakeMainClick = useCallback(async (contract: Contract) => {
      if (sendingRequest) {
        return;
      }
      console.log('make main', contract);

      const updateData: UpdateContractArgs = {
        address: contract.address,
        environment: contract.environment as 'testnet' | 'mainnet',
        status: 'Active',
        isMain: !contract.isMain,
        blockchain: contract.blockchain,
        contractType: 'certificates',
      }

      try {
        setSendingRequest("makeMain");
        const response = await dashboardApi?.updateContract(updateData);
        console.log('update response', response);
        if (!response.status) {
          throw new Error('Error archiving contract');
        }
        trackEvent(AnalyticEvent.MakeContractMain, contract);
        fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error updating contract ' + contract.address.toLocaleLowerCase());
      } finally {
        setSendingRequest(undefined);
      }
    }, [
      fetchDataAndReset,
    ]);
    const onPauseOrActive = useCallback(async (contract: Contract) => {
      if (sendingRequest) {
        return;
      }
      console.log('pause or active', contract);
      if (contract.status === 'Archive') {
        return
      }

      const updateData: UpdateContractArgs = {
        address: contract.address,
        environment: contract.environment as 'testnet' | 'mainnet',
        status: contract.status === 'Active' ? 'Paused' : 'Active',
        isMain: contract.isMain.toString() === 'true',
        blockchain: contract.blockchain,
        contractType: 'certificates',
      }

      try {
        setSendingRequest("pause");
        const response = await dashboardApi?.updateContract(updateData);
        console.log('update response', response);
        if (!response.status) {
          throw new Error('Error archiving contract');
        }
        trackEvent(`${contract.status === 'Active' ? 'Pause' : 'Activate'} Contract` as AnalyticEvent, contract);
        await fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error updating contract ' + contract.address.toLocaleLowerCase())
      } finally {
        setSendingRequest(undefined);
      }
    }, [
      fetchDataAndReset
    ]);
    const onArchive = useCallback(async (contract: Contract) => {
      if (sendingRequest) {
        return;
      }
      console.log('archive', contract);
      if (contract.status === 'Archive') {
        return
      }

      const updateData: UpdateContractArgs = {
        address: contract.address,
        environment: contract.environment as 'testnet' | 'mainnet',
        status: 'Archive',
        isMain: contract.isMain.toString() === 'true',
        blockchain: contract.blockchain,
        contractType: 'certificates',
      }

      try {
        setSendingRequest("archive");
        const response = await dashboardApi?.updateContract(updateData);
        console.log('update response', response);
        if (!response.status) {
          throw new Error('Error archiving contract ' + contract.address.toLocaleLowerCase());
        }
        trackEvent(AnalyticEvent.ArchiveContract, contract);
        await fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error archiving contract' + contract.address)
      } finally {
        setSendingRequest(undefined);
      }
    }, [fetchDataAndReset]);

    return (
      <CustomCellRenderer
        onArchive={onArchive}
        onMakeMainClick={onMakeMainClick}
        onPauseOrActivate={onPauseOrActive}
        sendingRequest={sendingRequest}
        {...props}
      />
    );
  };

  const CustomRewardsContractCellRenderer: React.FC<
    CustomCellRendererProps
  > = props => {
    const [sendingRequest, setSendingRequest] = useState<"makeMain" | "pause" | "archive">();
    const onMakeMainClick = useCallback(async (contract: Contract) => {
      if (sendingRequest) {
        return;
      }
      console.log('make main', contract);
      const updateData: UpdateContractArgs = {
        address: contract.address,
        environment: contract.environment as 'testnet' | 'mainnet',
        status: 'Active',
        isMain: !contract.isMain,
        blockchain: contract.blockchain,
        contractType: 'rewards',
      }

      try {
        setSendingRequest("makeMain");
        const response = await dashboardApi?.updateContract(updateData);
        console.log('update response', response);
        if (!response.status) {
          throw new Error('Error archiving contract');
        }
        trackEvent(AnalyticEvent.MakeContractMain, contract);
        fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error updating contract ' + contract.address.toLocaleLowerCase());
      } finally {
        setSendingRequest(undefined);
      }
    }, [
      fetchDataAndReset,
    ]);
    const onPauseOrActive = useCallback(async (contract: Contract) => {
      if (sendingRequest) {
        return;
      }
      console.log('pause or active', contract);
      if (contract.status === 'Archive') {
        return
      }

      const updateData: UpdateContractArgs = {
        address: contract.address,
        environment: contract.environment as 'testnet' | 'mainnet',
        status: contract.status === 'Active' ? 'Paused' : 'Active',
        isMain: contract.isMain,
        blockchain: contract.blockchain,
        contractType: 'rewards',
      }

      try {
        setSendingRequest("pause");
        const response = await dashboardApi?.updateContract(updateData);
        console.log('update response', response);
        if (!response.status) {
          throw new Error('Error archiving contract');
        }
        trackEvent(`${contract.status === 'Active' ? 'Pause' : 'Activate'} Contract` as AnalyticEvent, contract);
        fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error updating contract ' + contract.address.toLocaleLowerCase())
      } finally {
        setSendingRequest(undefined);
      }
    }, [
      fetchDataAndReset
    ]);
    const onArchive = useCallback(async (contract: Contract) => {
      if (sendingRequest) {
        return;
      }
      console.log('archive', contract);
      if (contract.status === 'Archive') {
        return
      }

      const updateData: UpdateContractArgs = {
        address: contract.address,
        environment: contract.environment as 'testnet' | 'mainnet',
        status: 'Archive',
        isMain: contract.isMain,
        blockchain: contract.blockchain,
        contractType: 'rewards',
      }

      try {
        setSendingRequest("archive");
        const response = await dashboardApi?.updateContract(updateData);
        console.log('update response', response);
        if (!response.status) {
          throw new Error('Error archiving contract ' + contract.address.toLocaleLowerCase());
        }
        trackEvent(AnalyticEvent.ArchiveContract, contract);
        fetchDataAndReset();
      } catch (error) {
        console.log('error', error);
        setError('Error archiving contract' + contract.address)
      } finally {
        setSendingRequest(undefined);
      }
    }, [fetchDataAndReset]);

    return (
      <CustomCellRenderer
        onArchive={onArchive}
        onMakeMainClick={onMakeMainClick}
        onPauseOrActivate={onPauseOrActive}
        sendingRequest={sendingRequest}
        {...props}
      />
    );
  };

  const dateValueFormatter = (params: ValueFormatterParams) => {
    if (params.value) {
      const date = new Date(params.value);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return null;
  }

  const renderBlockchainIcon = (blockchain: string) => {
    const iconMap: any = {
      ethereum: LocalIcons.EthereumLogo,
      base: LocalIcons.BaseLogo,
      polygon: LocalIcons.PolygonLogo,
      sui: LocalIcons.SuiLogo,
      none: LocalIcons.MentaportIcon,
    };
    return (
      <Tooltip title={blockchain}>
        <Image
          margin="0 auto"
          borderRadius={100}
          width={'1.5rem'}
          height={'1.5rem'}
          src={iconMap[blockchain]}
          alt={blockchain}
        />
      </Tooltip>
    );
  };

  const certificateColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (certificateContracts?.length) {
      Object.keys(certificateContracts[0]).forEach(key => {

        tableColumns.push({
          title: key,
          id: key,
          valueFormatter: key === 'updatedAt' ? dateValueFormatter : undefined,
        });
      });

      tableColumns.push({
        title: 'Actions',
        id: 'actions',
        customRenderer: CustomCertificateContractCellRenderer,
        width: 290
      });

      const blockchainColumn = tableColumns.find(
        (column) => column.id === 'blockchain'
      );
      if (blockchainColumn) {
        blockchainColumn.customRenderer = (props) => {
          return (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              {renderBlockchainIcon(props.value)}
            </Flex>
          );
        };
      }
    }

    return tableColumns;
  }, [certificateContracts]);

  const rewardsColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (rewardsContracts?.length) {
      Object.keys(rewardsContracts[0]).forEach(key => {
        tableColumns.push({
          title: key,
          id: key,
          type: 'string',
          valueFormatter: key === 'updatedAt' ? dateValueFormatter : undefined,
        });
      });

      tableColumns.push({
        title: 'Actions',
        id: 'actions',
        customRenderer: CustomRewardsContractCellRenderer,
        width: 290
      });

      const blockchainColumn = tableColumns.find(
        (column) => column.id === 'blockchain'
      );
      if (blockchainColumn) {
        blockchainColumn.customRenderer = (props) => {
          return (
            <Flex
              justifyContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              {renderBlockchainIcon(props.value)}
            </Flex>
          );
        };
      }
    }

    return tableColumns;
  }, [rewardsContracts]);

  return (
    <ManagementPage
      title={'Blockchain Admin'}
      path={'certificateAnalytics'}
      isLoading={isLoading}
      previousLocation={''}
      placeholder={getPlaceHolder()}
    >
      <Stack w={'100%'} spacing={6} p={30}>
        <SimpleGrid columns={2} spacing={10}>
          <Stack spacing={4}>
            <Text fontSize={26} fontWeight={600}>
              Deploy Contract
            </Text>
            <HStack w={'100%'}>
              <Select
                className="SelectContainer"
                placeholder={DeployTypes.placeholder}
                value={deployedContractType}
                onChange={onChangeDeployedContractType}
              >
                {DeployTypes.options?.map(deployOption => (
                  <option key={deployOption.id} value={deployOption.id}>
                    {deployOption.title}
                  </option>
                ))}
              </Select>
              <Select
                className="SelectContainer"
                placeholder={BlockChainTypes.placeholder}
                value={deployedcontractBlockchain}
                onChange={onChangeDeployedContractBlockChain}
              >
                {BlockChainTypes.options?.map(blockchainOption => (
                  <option key={blockchainOption.id} value={blockchainOption.id}>
                    {blockchainOption.title}
                  </option>
                ))}
              </Select>
              <Select
                className="SelectContainer"
                placeholder={EnvironmentTypes.placeholder}
                value={deployedContractEnvironment}
                onChange={onChangeDeployedContractEnvironment}
              >
                {EnvironmentTypes.options.map(environmentOption => (
                  <option
                    key={environmentOption.id}
                    value={environmentOption.id}
                  >
                    {environmentOption.title}
                  </option>
                ))}
              </Select>
            </HStack>
            <HStack w={'100%'}>
              {!deployedContractType && (
                <div style={{
                  height: 40, content: ""
                }}>
                </div>
              )}
              {deployedContractType === "rewards" && (
                <Input
                  placeholder="Rewards Contract URI"
                  value={newDeployContractUri}
                  onChange={onChangeDeployContractUri}
                />
              )}
              {deployedContractType === "certificates" && (
                <Input
                  placeholder="Contract Name"
                  value={newDeployContractName}
                  onChange={onChangeDeployContractName}
                />
              )}
              {deployedContractType === "certificates" && (
                <Input
                  placeholder="Contract Symbol"
                  value={newDeployContractSymbol}
                  onChange={onChangeDeployContractSymbol}
                />
              )}
            </HStack>
            <HStack w={'100%'}>
              <Checkbox onChange={onChangeDeployedContractIsMain}>
                Is Main
              </Checkbox>
              <Checkbox
                colorScheme="green"
                onChange={onChangeDeployedContractActivate}
              >
                Activate
              </Checkbox>
            </HStack>
            <Flex w={'100%'}>
              <Button
                w="100%"
                className={`Button Warning ${submittingDeployContract ? 'Disabled' : ''}`}
                onClick={onSubmitDeployContract}
                isLoading={submittingDeployContract}
                disabled={submittingDeployContract || submittingPreexistingContract}
              >
                {`${submittingDeployContract ? "Deploying..." : "Deploy New Contract"}`}
              </Button>
            </Flex>
          </Stack>

          <Stack spacing={4}>
            <Text fontSize={26} fontWeight={600}>
              Add Pre-Existing Contracts
            </Text>
            <HStack w={'100%'}>
              <Select
                className="SelectContainer"
                placeholder={DeployTypes.placeholder}
                value={newContractType}
                onChange={onChangeNewContractType}
              >
                {DeployTypes.options?.map(deployOption => (
                  <option key={deployOption.id} value={deployOption.id}>
                    {deployOption.title}
                  </option>
                ))}
              </Select>
              <Select
                className="SelectContainer"
                placeholder={BlockChainTypes.placeholder}
                value={newcontractBlockchain}
                onChange={onChangeNewContractBlockChain}
              >
                {BlockChainTypes.options?.map(blockchainOption => (
                  <option key={blockchainOption.id} value={blockchainOption.id}>
                    {blockchainOption.title}
                  </option>
                ))}
              </Select>
              <Select
                className="SelectContainer"
                placeholder={EnvironmentTypes.placeholder}
                value={newContractEnvironment}
                onChange={onChangeNewContractEnvironment}
              >
                {EnvironmentTypes.options?.map(environmentOption => (
                  <option
                    key={environmentOption.id}
                    value={environmentOption.id}
                  >
                    {environmentOption.title}
                  </option>
                ))}
              </Select>
            </HStack>
            <HStack w={'100%'}>
            <Input
                placeholder="Contract Name"
                value={newContractName}
                onChange={onChangeNewContractName}
              />
              <Input
                placeholder="Contract Address"
                value={newContractAddress}
                onChange={onChangeNewContractAddress}
              />
            </HStack>
            <HStack w={'100%'}>
              <Checkbox onChange={onChangeNewContractIsMain}>Is Main</Checkbox>
            </HStack>
            <Flex w={'100%'}>
              <Button
                className="Button Warning"
                w="100%"
                onClick={onSubmitPreexistingContract}
                isLoading={submittingPreexistingContract}
                disabled={submittingDeployContract || submittingPreexistingContract}
              >
                {`${submittingPreexistingContract ? "Adding..." : "Add Pre-Existing Contract"}`}
              </Button>
            </Flex>
          </Stack>
        </SimpleGrid>
        <Center>
          <Text color="red">{error}</Text>
        </Center>
        <DataTable
          title="Certificate Contracts"
          columns={certificateColumns}
          data={certificateContracts}
          getRowStyle={(params: any) => {
            if (params.data.status === "Archive") {
              return {
                backgroundColor: 'rgba(255, 193, 193, 0.6)',
              };
            }
            if (params.data.isMain === "true") {
              return {
                backgroundColor: 'rgba(193, 255, 222, 0.6)',
              };
            }
            return {};
          }}
        />
        <DataTable
          title="Rewards Contracts"
          columns={rewardsColumns}
          data={rewardsContracts}
          getRowStyle={(params: any) => {
            if (params.data.status === "Archive") {
              return {
                backgroundColor: 'rgba(255, 193, 193, 0.6)',
              };
            }
            if (params.data.isMain === "true") {
              return {
                backgroundColor: 'rgba(193, 255, 222, 0.6)',
              };
            }
            return {};
          }}
        />
      </Stack>
    </ManagementPage>
  );
};
