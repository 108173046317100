import { MentaportService } from '../services/mentaport-service';
import { useState, useEffect } from 'react';

import { getClient } from '../helpers/dashboard-helper';
import { useAtomApiKeys } from '../services/jotai-store-service'

export function useMentaport() {
  const [mentaport, setMentaport] = useState<MentaportService|null>(null);
  const [apiKey, isApiKeyLoading] = useAtomApiKeys();
 
  useEffect(() => {
    const loadMentaport = async () => {
      if (isApiKeyLoading || !apiKey) {return;}
      const client = getClient(apiKey);
      if (!client) {return;}
      setMentaport(new MentaportService(client));
    };
    loadMentaport();
  }, [apiKey, isApiKeyLoading]);
  return mentaport;
}
