import {
  Button,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { useDataFetcher } from '../../hooks/use-data-fetcher';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { useMentaport } from '../../hooks/use-mentaport';
import { IRule, IResults, TimeTypes, RuleStatus } from '@mentaport/supplement';
import './RuleList.scss';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { getPlaceHolder } from '../../helpers/list-helper';
import { Routes } from '../../data/routes';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';
import {
  getAccessType,
  getLocationType,
  getTimeType,
} from '../../helpers/mentaport-types-helper';
import { locationViewRegistry } from '../../helpers/options-helper';
import { getDates } from '../ManageRulePage/helpers/date-helper';
import { ShortcutCopy } from '../../components/commons/ShortCutCopy/ShortCutCopy';
import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';

const genericErrorMessage = 'There was a problem loading rules';

export const RuleListPage = () => {
  const dialog = useDialog();
  const { contractId, contractName } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const mentaportService = useMentaport();
  const location = useLocation();

  const [rules, rulesLoading] = useDataFetcher<IResults<IRule[]>>({
    serviceCall: () => {
      if (!contractId) {
        return;
      }
      return mentaportService?.getRules(contractId);
    },
    onError(error) {
      const errorMessage = error?.data?.message ?? "Error in backend."

      dialog.notify(MessageStatus.Error, 'Rule creation', errorMessage);
    },
    dependencies: [mentaportService],
    conditionForExecution: mentaportService !== undefined,
  });

  const getWhenValue = (rule: IRule) => {
    const type = getTimeType(rule.time.type);
    if (rule.time.type === TimeTypes.None) {
      return type;
    }
    return (
      <Flex direction={'column'}>
        <Text>{type}</Text>
        <Text>{getDates(rule.time)}</Text>
      </Flex>
    );
  };

  const renderRulesList = () => {
    return (
      <TableContainer overflowY={'scroll'}>
        <Table sx={{ minWidth: 650, maxWidth: 1200 }} aria-label="simple table">
          <Thead>
            <Tr>
              <Th width={16}>ID</Th>
              <Th>Name</Th>
              <Th width={10}>Amount</Th>
              <Th>Who</Th>
              <Th>When</Th>
              <Th>Where</Th>

              <Th width={20}>Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {rules?.data?.map((rule, index) => (
              <Tr
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <Td width={16}>
                  <ShortcutCopy id={rule.ruleId ?? ''} />
                </Td>
                <Td placeSelf={'flex-start'}>
                  <strong>{rule.name}</strong>
                </Td>
                <Td align="center" textAlign="center">
                  {rule.curAmount!.toString() + '/' + rule.amount.toString()}
                </Td>
                <Td align="left">{getAccessType(rule.access.type)}</Td>
                <Td align="left">{getWhenValue(rule)}</Td>
                <Td align="left">
                  <Flex direction={'column'}>
                    <Text>{getLocationType(rule.location.type)}</Text>
                    <Text fontSize={'sm'}>
                      {locationViewRegistry.get(rule.location.type)?.(
                        rule.location
                      )}
                    </Text>
                  </Flex>
                </Td>

                <Td align="center" width={20}>
                  {renderStatus(rule)}
                </Td>
                <Td className="rowTools" align="left">
                  <Button
                    marginLeft={1}
                    onClick={() => {
                      trackEvent(AnalyticEvent.NavigationToManageContractRules)
                      navigate(
                        `/contracts/${getContractEnvironment().toLowerCase()}/${contractId}/${contractName}/rules/edit/${
                          rule.ruleId
                        }`
                      );
                    }}
                  >
                    Edit
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    );
  };

  const renderRules = () => {
    return (
      <Flex
        className="created-keys"
        alignContent="start"
        direction="column"
        height={'100vh'}
        overflowY={'auto'}
        paddingBottom={'4rem'}
      >
        <Heading
          size={{ base: 'xs', lg: 'sm' }}
          paddingLeft={'1rem'}
          paddingTop={'1rem'}
          paddingBottom={'1rem'}
          fontWeight="bolder"
        >
          <Text className="ManagementPageTitle">
            <strong>RULES LIST</strong>
          </Text>
        </Heading>
        {renderRulesList()}
      </Flex>
    );
  };

  const getManagementButtons = () => {
    return [
      <Button
        key={`button-test-contract`}
        variant="dark"
        isDisabled={true}
        size="sm"
      >
        Test Contract
      </Button>,
      <Button
        key={`button-create-Rule`}
        variant="pink"
        size="sm"
        onClick={() => {
          trackEvent(AnalyticEvent.NavigationToCreateRule)
          navigate(
            `/contracts/${getContractEnvironment().toLowerCase()}/${contractId}/${contractName}/rules/new`
          )}
        }
      >
        Create Rule
      </Button>,
    ];
  };

  const renderStatus = (rule: IRule) => {
    let statusOptions = {
      variant: 'default',
      label: 'default',
    };
    if (rule.status === RuleStatus.Active) {
      statusOptions = {
        variant: 'mint',
        label: 'Active',
      };
    } else if (rule.status === RuleStatus.Claimed) {
      statusOptions = {
        variant: 'black',
        label: 'Claimed',
      };
    } else if (rule.status === RuleStatus.NonActive) {
      statusOptions = {
        variant: 'black',
        label: 'Inactive',
      };
    } else if (rule.status === RuleStatus.Expired) {
      statusOptions = {
        variant: 'black',
        label: 'Expired',
      };
    } else if (rule.status === RuleStatus.Pending) {
      statusOptions = {
        variant: 'black',
        label: 'Pending',
      };
    }
    return (
      <Button width={100} cursor={'default'} variant={statusOptions.variant}>
        {statusOptions.label}
      </Button>
    );
  };

  const getContractEnvironment = () => {
    return location.pathname.startsWith('/contracts/blockchain')
      ? 'Blockchain'
      : 'Mezzanine';
  };

  const buildBreadcrumbs = () => {
    const rootUrl = 'contracts/';
    return [
      {
        label: 'Contracts',
        url: rootUrl,
      },
      {
        label: contractName ?? '',
      },
    ];
  };

  return (
    <ManagementPage
      isLoading={rulesLoading}
      title={getContractEnvironment()}
      buttons={getManagementButtons()}
      path={''}
      placeholder={getPlaceHolder()}
      breadcrumbs={buildBreadcrumbs()}
      previousLocation={Routes.Contracts}
    >
      {renderRules()}
    </ManagementPage>
  );
};

