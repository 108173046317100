import { Button } from '@chakra-ui/react';
import { useDialog } from '../../../hooks/use-dialog';
import { MessageStatus } from '../../../services/dialog';
import { FunctionComponent } from 'react';

export interface ShortcutCopyProps {
  id: string;
  color?:string;
}

export const ShortcutCopy: FunctionComponent<ShortcutCopyProps> = props => {
  const dialog = useDialog();
  return (
    <Button
      fontSize="sm"
      fontFamily="monospace"
      variant={props.color? props.color: 'yellow'}
      textAlign={'center'}
      onClick={() => {
        navigator.clipboard.writeText(props.id);
        dialog.notify(MessageStatus.Success, '', 'Copied to clipboard');
      }}
    >
      {`${props.id.substring(0, 8)}...`}
    </Button>
  );
};
