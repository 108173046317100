import ReactDOM from 'react-dom/client';
import './index.scss';
// import reportWebVitals from './framework/reportWebVitals';
import { Kernel } from './framework/Kernel';
import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { theme } from './framework/theme';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";
import { BrowserRouter } from "react-router-dom";

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import { Provider, createStore } from 'jotai'

const newTheme = createTheme({
  palette: {
    primary: pink,
    secondary: {
      main: '#f5fb8b',
    },
    error: {
      main: '#c04f2f',
    },
  },
});


const myStore = createStore()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      
      <ThemeProvider theme={newTheme}>
        <ChakraProvider theme={theme}>
        <BrowserRouter>
        <Auth0ProviderWithNavigate>
          <Provider store={myStore} >
            <Kernel />
          </Provider>
          </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </ChakraProvider>
      </ThemeProvider>
   
  </React.StrictMode>
);

// reportWebVitals();
