import { Box, Flex, HStack, Text } from '@chakra-ui/layout';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import {
  ManagementPage,
  ManagementPageProps,
} from '../ManagementPage/ManagementPage';
import {
  ContractEnvironment,
  ContractStatus,
  ICertificateContract,
} from '@mentaport/supplement';
import { Button } from '@chakra-ui/button';
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/table';
import { ShortcutCopy } from '../ShortCutCopy/ShortCutCopy';
import { useDialog } from '../../../hooks/use-dialog';
import { shouldBeLoading } from '../../../helpers/contract-list-helper';
import { Image } from '@chakra-ui/image';
import { LocalIcons } from '../../../assets/icons/icons';
import { MessageStatus } from '../../../services/dialog';
import { Icon } from '@chakra-ui/icon';
import { GiMoebiusTriangle } from 'react-icons/gi';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/tabs';
import { DataTable, TableColumn } from '../Table/Table';
import { Block } from '@mui/icons-material';
import { CustomCellRendererProps } from 'ag-grid-react';
import { Tooltip } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import { authentication } from '../../../services/authentication';

export interface ContractListComponentProps {
  managementPageProps: ManagementPageProps;
  environments: ContractEnvironmentContent[];
  complexActivationLoading?: boolean;
  onEditContract: (contract: ICertificateContract) => void;
  onPauseContract: (contract: ICertificateContract) => Promise<void>;
  onResumeContract: (contract: ICertificateContract) => Promise<void>;
  onActivateContract: (contract: ICertificateContract) => Promise<void>;
  onManageContractRules: (contractId: string, contractName: string) => void;
  onUsersDownLoad?: (contract: ICertificateContract) => Promise<void>;
  onShowContractAnalytics: (contractId: string, contractName: string) => void;
}
export interface ContractEnvironmentContent {
  environmentType: ContractEnvironment;
  contracts: ICertificateContract[];
}

enum ContractActions {
  Activate = 'activate',
  Pause = 'pause',
  Resume = 'resume',
  Download = 'download',
}

export const ContractListComponent: FunctionComponent<
  ContractListComponentProps
> = ({ managementPageProps,
  environments,
  complexActivationLoading,
  onEditContract,
  onPauseContract,
  onResumeContract,
  onActivateContract,
  onManageContractRules,
  onUsersDownLoad,
  onShowContractAnalytics
}) => {
    const [asyncActionIsRunning, setAsyncActionIsRunning] =
      useState<boolean>(false);
    const [focusedContractId, setFocusOnContractId] = useState<string>('');
    const [activeAction, setActiveAction] = useState<ContractActions | undefined>();
    const dialog = useDialog();
    // const navigate = useNavigate();
    const [currentComplexActivationLoading, setComplexActivationLoading] = useState(
      complexActivationLoading
    );

    useEffect(() => {
      setComplexActivationLoading(complexActivationLoading);
    }, [complexActivationLoading]);


    const EnvironmentTable: React.FC<{ environment: ContractEnvironmentContent }> = ({ environment }) => {
      const { contracts, environmentType } = environment;

      const renderBlockchainIcon = (blockchain: string) => {
        const iconMap: any = {
          ethereum: LocalIcons.EthereumLogo,
          base: LocalIcons.BaseLogo,
          polygon: LocalIcons.PolygonLogo,
          sui: LocalIcons.SuiLogo,
          none: LocalIcons.MentaportIcon,
        };
        return (
          <Tooltip title={blockchain}>
          <Image
            margin="0 auto"
            borderRadius={100}
            width={'1.5rem'}
            height={'1.5rem'}
            src={iconMap[blockchain]}
            alt={blockchain}
          />
          </Tooltip>
        );
      };

      const renderActionButtons = (props: CustomCellRendererProps) => {
        const contract = props.data as ICertificateContract;
        return (
          <HStack>
            {renderStatus(contract)}
            {contract.status !== ContractStatus.Archive &&
              <HStack>
                {renderLifecycleContractTools(contract)}
                <Button
                  onClick={() => onEditContract(contract)}
                >
                  Edit
                </Button>

                <Button
                  disabled={true}
                  onClick={() => {
                    onShowContractAnalytics(
                      contract.contractId,
                      contract.name
                    );
                  }}
                >
                  Analytics
                </Button>
              </HStack>
            }
          </HStack>
        );
      }

      const tableColumns: TableColumn[] = useMemo(() => {
        let tableColumns: TableColumn[] = [];
        if (contracts?.length) {
          const nonMezzanineColumns = ['blockchain', 'address', 'projectId'];
          const keysToInclude = ['name', 'customerId']
          const totalKeysToInclude = ['contractId', ...(environmentType !== ContractEnvironment.Mezzanine ? nonMezzanineColumns : []), ...keysToInclude];
          totalKeysToInclude.forEach((key) => {
            // key is camelCase, so we separate the words with a space and capitalize the first letter
            const title = key
              .replace(/([A-Z])/g, ' $1')
              .replace(/^./, (str) => str.toUpperCase());
            tableColumns.push({
              title,
              id: key,
              width: environmentType !== ContractEnvironment.Mezzanine ? 140 : undefined,
            });
          });

          if (environmentType == ContractEnvironment.Mezzanine) {
            const columnsToRemove = ['blockchain', 'address', 'projectId'];
            tableColumns = tableColumns.filter(
              (column) => !columnsToRemove.includes(column.id)
            );
          }

          tableColumns.push({
            title: 'Actions',
            id: 'actions',
            width: 380,
            customRenderer: (props) => renderActionButtons(props),
          });

          const blockchainColumn = tableColumns.find(
            (column) => column.id === 'blockchain'
          );
          if (blockchainColumn) {
            blockchainColumn.customRenderer = (props) => {
              return (
                <Flex
                  justifyContent={'center'}
                  alignItems={'center'}
                  width={'100%'}
                >
                  {renderBlockchainIcon(props.value)}
                </Flex>
              );
            };
          }
        }
        return tableColumns;
      }, [
        environmentType,
        contracts,
      ])

      return (
        <DataTable columns={tableColumns} data={contracts} rowHeight={60} height={500} getRowStyle={() => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        })} />
      )
    };

    const handleAsyncAction = async (
      contract: ICertificateContract,
      action?: (contract: ICertificateContract) => Promise<void> | undefined
    ) => {
      if (!action) {
        return;
      }
      try {
        setAsyncActionIsRunning(true);
        setFocusOnContractId(contract.contractId);
        await action(contract);
      } catch (error: any) {
        dialog.notify(
          MessageStatus.Error,
          `${contract.environment === ContractEnvironment.Mainnet
            ? 'Mezzanine'
            : 'Blockchain'
          } Contracts`,
          error?.data.message ?? 'There was an error during thee process'
        );
      } finally {
        setAsyncActionIsRunning(false);
      }
    };

    const renderLifecycleContractTools = (contract: ICertificateContract) => {

      return (
        <>
          {contract.status === ContractStatus.NonActive && (
            <Button
              isLoading={
                shouldBeLoading(
                  contract.contractId,
                  focusedContractId,
                  asyncActionIsRunning,
                  activeAction === ContractActions.Activate
                ) || complexActivationLoading
              }
              key="btn-pause"
              width={10}
              cursor={'pointer'}
              variant="orange"
              aria-label="Activate"
              onClick={() => {
                console.log("clicked")
                setActiveAction(ContractActions.Activate);
                handleAsyncAction(contract, () =>
                  onActivateContract(contract)
                );
              }}
            >
              <Image
                margin="0 auto"
                borderRadius={0}
                objectFit={'cover'}
                width={'1rem'}
                height={'1rem'}
                src={LocalIcons.Launch}
                alt={'Pause'}
              />
            </Button>
          )}

          {contract.status === ContractStatus.Active && (

            <Button
              isLoading={shouldBeLoading(
                contract.contractId,
                focusedContractId,
                asyncActionIsRunning,
                activeAction === ContractActions.Pause
              )}
              key="btn-pause"
              width={10}
              cursor={'pointer'}
              onClick={() => {
                setActiveAction(ContractActions.Pause);
                handleAsyncAction(contract, () => {
                  return onPauseContract(contract);
                });
              }}
            >
              <Image
                margin="0 auto"
                borderRadius={0}
                width={'1rem'}
                height={'1rem'}
                src={LocalIcons.Pause}
                alt={'Pause'}
              />
            </Button>
          )}
          {contract.status === ContractStatus.Paused && (
            <Button
              isLoading={shouldBeLoading(
                contract.contractId,
                focusedContractId,
                asyncActionIsRunning,
                activeAction === ContractActions.Resume
              )}
              key="btn-resume"
              width={10}
              cursor={'pointer'}
              onClick={() => {
                setActiveAction(ContractActions.Resume);
                handleAsyncAction(contract, () => {
                  return onResumeContract(contract);
                });
              }}
            >
              <Image
                margin="0 auto"
                borderRadius={0}
                width={'1rem'}
                height={'1rem'}
                src={LocalIcons.Play}
                alt={'Resume'}
              />
            </Button>
          )}
          {contract.status === ContractStatus.Archive && (
            <Button
              isActive={false}
              key="btn-resume"
              width={10}

            >
              <Image
                margin="0 auto"
                borderRadius={0}
                width={'1rem'}
                height={'1rem'}
                src={LocalIcons.Play}
                alt={'Resume'}
              />
            </Button>
          )}
        </>
      );
    };

    const renderStatus = (contract: ICertificateContract) => {
      let variantName = '';
      let label = '';
      let color = 'black'
      if (contract.status === ContractStatus.NonActive) {
        label = 'Inactive';
        variantName = 'black';
        color = '#ddd'
      } else if (contract.status === ContractStatus.Active) {
        label = 'Active';
        variantName = 'mint';
      } else if (contract.status === ContractStatus.Paused) {
        label = 'Paused';
        variantName = 'orange';
      } else if (contract.status === ContractStatus.Pending) {
        label = 'Pending';
        variantName = 'yellow';
      } else if (contract.status === ContractStatus.Archive) {
        label = 'Archive';
        variantName = 'red';
        color = '#ddd'
      }

      return (
        <Button key="status" width={100} cursor={'default'} variant={variantName} color={color}>
          {label}
        </Button>
      );
    };

    return (
      <Flex flexDirection="column" height={'100%'} width="100%">
        <ManagementPage
          title={managementPageProps.title}
          buttons={managementPageProps.buttons}
          path={managementPageProps.path}
          hideBackButton={managementPageProps.hideBackButton}
          isLoading={managementPageProps.isLoading}
          placeholder={managementPageProps.placeholder}
          breadcrumbs={managementPageProps.breadcrumbs}
          previousLocation={managementPageProps.previousLocation}
        >
          <Tabs colorScheme="black" overflowY={'scroll'}>
            <TabList>
              {environments.map((environment, index) => {
                if (environment.environmentType !== ContractEnvironment.Localhost) {
                  return (
                    <Tab key={index} fontSize={'large'}>
                      {environment?.environmentType.toString().toUpperCase()}
                    </Tab>
                  );
                }
              })}
            </TabList>
            <TabPanels>

              {environments.map((environment, index) => {
                return (
                  <TabPanel key={index}>
                    <Box key={environment.environmentType}>
                      <EnvironmentTable environment={environment} />
                    </Box>
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </ManagementPage>
      </Flex>
    );
  };
