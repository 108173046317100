import { GiMoebiusTriangle } from 'react-icons/gi';
import { HiHome, HiOutlineArrowSmRight, HiOutlineUsers } from 'react-icons/hi';
import { DiGoogleAnalytics } from "react-icons/di";
import { MdAnalytics } from "react-icons/md";
import { FaCubes } from "react-icons/fa";

enum NabvarSlug {
  home = 'home',
  contracts = 'contracts',
  users = 'users',
  contractAnalytics = 'contractAnalytics',
  certificateAnalytics = 'certificateAnalytics',
  blockchainAdmin = 'blockchainAdmin',
}

export interface NavbarItem {
  icon: any;
  slug: NabvarSlug;
  displayName: string;
  style?: any;
}

export interface Config {
  companyName: string;
  primaryColor: string;
  darkerColor: string;
  secondaryColor: string;
  linearGradient: string[];
  '404': string;
  contactUri: string;
  Navbar: NavbarItem[];
  icon: string;
  smallIcon: string;
}

export const data: Config = {
  companyName: 'Mentaport',
  primaryColor: 'black',
  darkerColor: '#f2f2f2',
  secondaryColor: '#1B1B1B',
  linearGradient: ['white', 'white'],
  '404': '404 Page not found',
  contactUri: 'mailto:support@mentaport.com',
  Navbar: [
    {
      icon: HiHome,
      slug: NabvarSlug.home,
      displayName: 'Home',
    },
    {
      icon: GiMoebiusTriangle,
      slug: NabvarSlug.contracts,
      displayName: 'Contracts',
    },
    // {
    //   icon: HiOutlineArrowSmRight,
    //   slug: 'contracts/mezzanine',
    //   displayName: 'Mezzanine',
    // },
    // {
    //   icon: HiOutlineArrowSmRight,
    //   slug: 'contracts/blockchain',
    //   displayName: 'Blockchain',
    // },
    {
      icon: HiOutlineUsers,
      slug: NabvarSlug.users,
      displayName: 'Users',
    },
    {
      slug: NabvarSlug.contractAnalytics,
      displayName: 'Contract Analytics',
      icon: DiGoogleAnalytics
    },
    {
      icon: MdAnalytics,
      slug: NabvarSlug.certificateAnalytics,
      displayName: 'Certificate Analytics'
    },
    {
      icon: FaCubes,
      slug: NabvarSlug.blockchainAdmin,
      displayName: 'Blockchain Admin',
      style: {
        marginTop: 25,
      }
    },
  ],
  icon: '/images/brand/mentaport-icon.svg',
  smallIcon: '/images/brand/mentaport-small-icon.svg',
};
