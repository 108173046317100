import iconNotification from './resources/np_notification.svg';
import iconLogOut from './resources/np_log-out.svg';
import iconHome from './resources/np_home.svg';
import iconUser from './resources/np_user.svg';
import iconClose from './resources/np_close.svg';
import iconUserV2 from './resources/np_user2.svg';
import iconDiagonalArrow from './resources/np_arrow-out.svg';
import iconBaseLogo from './resources/base_Symbol_Blue.svg';
import iconSuiLogo from './resources/sui-logo.svg';
import iconPolygonLogo from './resources/polygon-logo.svg';
import iconEthereumLogo from './resources/ethereum-logo.svg';
import iconOptionCheckmark from './resources/option-checkmark.svg';
import iconOptionCustom from './resources/option-custom.svg';
import iconUSA from './resources/usa.svg';
import videoMark from './resources/video_mark.svg';
import sdk from './resources/sdk.svg';
import tutorials from './resources/tutorials.svg';
import github from './resources/open_source.svg';
import play from './resources/np_play.svg';
import pause from './resources/np_pause.svg';
import launch from './resources/np_launch.svg';
import mentaportIcon from './resources/mentaport_icon.svg';


export const LocalIcons = {
  Close: iconClose,
  DiagonalArrow: iconDiagonalArrow,
  Home: iconHome,
  LogOut: iconLogOut,
  Notification: iconNotification,
  User: iconUser,
  UserV2: iconUserV2,
  MentaportIcon:mentaportIcon,
  BaseLogo:iconBaseLogo,
  SuiLogo: iconSuiLogo,
  PolygonLogo: iconPolygonLogo,
  EthereumLogo: iconEthereumLogo,
  OptionCheckmark: iconOptionCheckmark,
  OptionCustom: iconOptionCustom,
  USA: iconUSA,
  VideoMark: videoMark,
  SDK: sdk,
  Tutorials: tutorials,
  GitHub: github,
  Play: play,
  Pause: pause,
  Launch: launch,
};
