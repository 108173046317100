import { useDataFetcher } from '../hooks/use-data-fetcher';
import { useDashboardApi } from '../hooks/use-dashboard-api';
import { User } from '../data/user';

import { useAtom, atom, useStore } from 'jotai';

const apiKey = atom('')
const certUsers = atom<User[]>([])
export const firstRule = atom(false)

export const useAtomApiKeys = (): [apikeys: string, isLoading: boolean] => {
  

  const [apiKeyStore, setApi] = useAtom(apiKey);
  const apiClient = useDashboardApi();
  const [apiKeys, isLoading] = useDataFetcher<string[]>({
    serviceCall: () => {
      return apiClient?.fetchApiKeys();
    },
    dependencies: [apiClient],
    conditionForExecution:
      apiClient !== undefined &&
      (apiKeyStore === undefined || apiKeyStore.length === 0),
    onFinish(response) {
      // console.log("api")
      let val = ''
      if (response && response.length > 0){
        val = response[0]
      }
      setApi(val)
      // store.set(apiKey,val)
    },
  });

  return [apiKeyStore ?? apiKeys ?? '', isLoading];
};


export const useAtomUsers = (): [users: User[], isLoading: boolean] => {
  
  const [usersStore, setUsers] = useAtom(certUsers);
  const apiClient = useDashboardApi();

  const [users, isLoading] = useDataFetcher<User[]>({
    serviceCall: () => {
      return apiClient?.getUsers();
    },
    dependencies: [apiClient],
    conditionForExecution:
      apiClient !== undefined &&
      (usersStore === undefined || usersStore.length === 0),
    onFinish(response) {
      console.log("get users")
      let val:User[] = []
      if (response && response.length > 0){
        val = response 
      }
      setUsers(val);
    },
  });
  return [usersStore ?? users ?? [], isLoading];
};

export const useSetAtomRule = (isFirstRule:boolean) => {
  const store = useStore()
  store.set(firstRule, isFirstRule)
};

export const useAtomIsFirstRule = (): boolean =>{
  const store = useStore()
  return store.get(firstRule)
}

export const clearAppData = atom(
  null,
  (get, set) => {
      // Reset each atom explicitly
      set(apiKey, '');
      set(certUsers, []);
  }
);