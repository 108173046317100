import { LoginPage } from './Pages/LoginPage/LoginPage';
import { Route, Routes } from 'react-router-dom';
import { LoginCallbackPage } from './Pages/LoginCallBackPage/LoginCallbackPage';
import { PageNotFoundPage } from './Pages/PageNotFound/PageNotFound';
// import { ContractAnalyticPage } from './Pages/ContractAnalyticPage/ContractAnalyticPage';
import { CreateAPIKeyPage } from './Pages/CreateAPIKeyPage/CreateAPIKeyPage';
import { EmptyLayout } from './Layouts/EmptyLayout/EmptyLayout';
import { MainLayout } from './Layouts/MainLayout/MainLayout';
import { ManageRulePage } from './Pages/ManageRulePage/ManageRulePage';
import { ManageContractPage } from './Pages/ManageContractPage/ManageContractPage';
import { RuleListPage } from './Pages/RuleListPage/RuleListPage';
import { HomePage } from './Pages/HomePage/HomePage';
import { ContractListPage } from './Pages/ContractListPage/ContractListPage';
import { UsersPage } from './Pages/UsersPage/UsersPage';
import { UserContractsPage } from './Pages/UserContractsPage/UserContractsPage';

import { AuthenticationGuard } from "../framework/components/authentication-guard";
import { ContractsAnalyticsPage } from './Pages/ContractsAnalyticsPage/ContractsAnalyticsPage';
import { CertificateAnalyticsPage } from './Pages/CertificateAnalyticsPage/CertificateAnalyticsPage';
import { BlockchainControlPage } from './Pages/BlockchainControlPage/BlockchainControlPage';

export const Kernel: React.FC = () => {
  return (

      <Routes>
        <Route path="/" 
          // element={<MainLayout />}>
          element={<AuthenticationGuard component={MainLayout} />} >
          <Route path="/" 
           element={<AuthenticationGuard component={HomePage} />}
          // element={<HomePage />} 
          />
           <Route path="home" 
           element={<AuthenticationGuard component={HomePage} />}
          // element={<HomePage />} 
          />
          <Route path="create-api-key" 
          element={<AuthenticationGuard component={CreateAPIKeyPage} />}
          // element={<CreateKeyPage />}
           />
          <Route path="users" 
            element={<AuthenticationGuard component={UsersPage} />}
          // element={<UsersPage />} 
          />
          <Route
            path="users/contracts/:id/:email"
            element={<AuthenticationGuard component={UserContractsPage} />}
            // element={<UserContractsPage />}
          />

          {/* Contract Creation */}
          {/* <Route
            path="contracts/new"
            element={<ManageContractPage />}
          /> */}

          <Route
            path="contracts/new/:customerId"
            element={<AuthenticationGuard component={ManageContractPage} />}
            //element={<ManageContractPage />}
          />
          {/* Contract Edition */}
          <Route
            path="contracts/edit/:contractId/:customerId"
            element={<AuthenticationGuard component={ManageContractPage} />}
           // element={<ManageContractPage />}
          />
          <Route
            path="contracts/blockchain/edit/:contractId/:customerId"
            element={<AuthenticationGuard component={ManageContractPage} />}
            // element={<ManageContractPage />}
          />
          <Route
            path="contracts/mezzanine/edit/:contractId/:customerId"
            element={<AuthenticationGuard component={ManageContractPage} />}
            // element={<ManageContractPage />}
          />
          {/* Contract List */}
          <Route path="contracts"
            element={<AuthenticationGuard component={ContractListPage} />}
          //  element={<BlockchainListPage />}
            />

          {/* Contract Analytics - Not Yet Released */}
        <Route path="contractAnalytics"
          element={<AuthenticationGuard component={ContractsAnalyticsPage} />} />

        {/* Certificate Analytics - Not Yet Released */}
        <Route path="certificateAnalytics"
          element={<AuthenticationGuard component={CertificateAnalyticsPage} />} />

        <Route path="blockchainAdmin"
          element={<AuthenticationGuard component={BlockchainControlPage} />} />

          {/* <Route
            path="contracts/:id/:name/analytics"
            element={<AuthenticationGuard component={ContractAnalyticPage} />}
            // element={<ContractDetailsPage />}
          /> */}

          {/* Rules Creation */}
          <Route
            path="contracts/:environment/:contractId/:contractName/rules/new"
            element={<AuthenticationGuard component={ManageRulePage} />}
            // element={<ManageRulePage />}
          />
          {/* Rules Edition */}

          <Route
            path="contracts/:contractId/:contractName/rules/edit/:ruleId"
            element={<AuthenticationGuard component={ManageRulePage} />}
            // element={<ManageRulePage />}
          />
          <Route
            path="contracts/blockchain/:contractId/:contractName/rules/edit/:ruleId"
            element={<AuthenticationGuard component={ManageRulePage} />}
            // element={<ManageRulePage />}
          />
          <Route
            path="contracts/mezzanine/:contractId/:contractName/rules/edit/:ruleId"
            element={<AuthenticationGuard component={ManageRulePage} />}
            // element={<ManageRulePage />}
          />
          {/* Rules List */}

          <Route
            path="contracts/:contractId/:contractName/rules"
            element={<AuthenticationGuard component={RuleListPage} />}
            // element={<RuleListPage />}
          />
          <Route
            path="contracts/blockchain/:contractId/:contractName/rules"
            element={<AuthenticationGuard component={RuleListPage} />}
            // element={<RuleListPage />}
          />
          <Route
            path="contracts/mezzanine/:contractId/:contractName/rules"
            element={<AuthenticationGuard component={RuleListPage} />}
            // element={<RuleListPage />}
          />

        </Route>
        <Route path="/" element={<EmptyLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="login-callback" element={<LoginCallbackPage />} />
        </Route>
        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    
  );
};