import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

// import { authentication } from '../../services/authentication';
import { Spinner } from '@chakra-ui/react';

const Page = () => {
  const { isAuthenticated } = useAuth0();

  const navigate = useNavigate();
  const [error, setError] = useState('');
  useEffect(() => {
    const completeLogin = async () => {
      try {
       if(isAuthenticated)
          navigate('/home');
      } catch (err: any) {
        setError(err?.errorDescription);
        console.error(err);
      }
    };
    completeLogin();
  }, [isAuthenticated, navigate]);
  return (
    <div>
      {error === '' && <Spinner size="xl" />}
      {error !== '' && <p>{error} :/</p>}
    </div>
  );
};

export const LoginCallbackPage = Page;
