import { MentaportSupplementSDK } from '@mentaport/supplement';
import {
  Environment,
  IRule,
  IResults,
  IContractCreateCert,
  IContract,
  ContractStatus,
  IUpdateRule,
  IUpdateContract,
  ICertificateContract,
  IContractsAnalytics,
  IContractIdAnalytics,
  ICertificateAnalytics,
  ICertIdWalletAnalytics
} from '@mentaport/supplement';

import { configuration } from '../../config/configuration';

export class MentaportService {
  supClient: MentaportSupplementSDK;
  constructor(supClient: MentaportSupplementSDK) {
    this.supClient = supClient;
    this.supClient.setClientEnv(this.getMentaportEnvironment());
  }

  private getMentaportEnvironment = (): Environment => {
    switch (configuration.mentaport.stageId) {
      case 'development':
        return Environment.DEVELOPMENT;
      case 'staging':
        return Environment.STAGING;
      case 'production':
        return Environment.PRODUCTION;
      default:
        return Environment.STAGING;
    }
  };

  //---------------------------------------------
  // Contract
  public async createNewCertificateContract(
    contract: IContractCreateCert
  ): Promise<ICertificateContract> {

    const newContract =
      await this.supClient.contractSDK.createNewCertificateContract(contract);
    if (!newContract.status || !newContract.data) {
      throw new Error('Contract creation failed');
    }
    return newContract.data;
  }

  public async getContracts(customerId?: string):Promise<IResults<ICertificateContract[]> > {
    return await this.supClient.contractSDK.getContracts<ICertificateContract[]>(customerId);
  }

  public async getContractById(contractId: string, customerId: string) {
    return await this.supClient.contractSDK.getContractById<ICertificateContract>(
      contractId,
      customerId
    );
  }

  public async updateContract(contractId: string, contract: IUpdateContract) {
    return await this.supClient.contractSDK.updateContractById<ICertificateContract>(
      contractId,
      contract
    );
  }

  public async activateCertificateContract(
    contract: IContract,
    customerId: string
  ) {
    return await this.supClient.contractSDK.activateContract(
      contract.contractId,
      customerId
    );
  }

  public async fetchMezzanineData(contract: IContract) {
    return await this.supClient.contractSDK.getMezzanineById(
      contract.contractId
    );
  }

  public async pauseContract(contract: IContract, customerId: string) {
    return await this.supClient.contractSDK.updateContractStatusById(
      contract.contractId,
      ContractStatus.Paused,
      customerId
    );
  }

  public async resumeContract(contract: IContract, customerId: string) {
    return await this.supClient.contractSDK.updateContractStatusById(
      contract.contractId,
      ContractStatus.Active,
      customerId
    );
  }

  public async activateMezzanineContract(
    contract: IContract,
    customerId: string
  ) {
    return await this.supClient.contractSDK.activateMezzanineContract(
      contract.contractId,
      customerId
    );
  }

  //---------------------------------------------
  // Analytics
  public async getContractAnalytics():Promise<IResults<IContractsAnalytics>> {
    return await this.supClient.analyticsSDK.getContractAnalytics();
  }
  public async getContractIdAnalytics(contractId: string, queryDateMin: string, queryDateMax:string):Promise<IResults<IContractIdAnalytics>> {
    return await this.supClient.analyticsSDK.getContractIdAnalytics(
      contractId,
      queryDateMin,
      queryDateMax
    );
  }
  public async getCertificatesAnalytics():Promise<IResults<ICertificateAnalytics>>  {
    return await this.supClient.analyticsSDK.getCertificatesAnalytics();
  }
  public async getCertIdWalletAnalytics(queryDateMin: string, queryDateMax:string, certId: string='na',wallet: string='na'):Promise<IResults<ICertIdWalletAnalytics>> {
    return await this.supClient.analyticsSDK.getCertIdWalletAnalytics(
      certId,
      wallet,
      queryDateMin,
      queryDateMax
    );
  }
  //---------------------------------------------
  // Rules
  public async createNewRules(rules: IRule[]) {
    return await this.supClient.rulesSDK.createNewRules(rules);
  }

  public async updateRuleById(
    ruleId: string,
    contractId: string,
    rule: IUpdateRule
  ) {
    return await this.supClient.rulesSDK.updateRuleById(
      ruleId,
      contractId,
      rule
    );
  }

  public async getRuleById(
    ruleId: string,
    contractId: string
  ): Promise<IResults<IRule>> {
    return await this.supClient.rulesSDK.getRuleById(contractId, ruleId);
  }

  public async updateRule(
    ruleId: string,
    contractId: string,
    rule: IUpdateRule
  ): Promise<IResults<IUpdateRule>> {
    return await this.supClient.rulesSDK.updateRuleById(
      ruleId,
      contractId,
      rule
    );
  }

  public async getRules(contractId: string): Promise<IResults<IRule[]>> {
    return await this.supClient.rulesSDK.getAllRules(contractId);
  }
}
