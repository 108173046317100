import {
  Box,
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
  useOutsideClick,
} from '@chakra-ui/react';
import { FunctionComponent, useCallback, useRef } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { MdOutlineCalendarMonth } from 'react-icons/md';

type ValuePiece = Date | null;

export type DatePickerValue = ValuePiece | [ValuePiece, ValuePiece];

export interface DatePickerProps {
  value?: DatePickerValue;
  onChange: (value: DatePickerValue) => void;
}

export const DatePicker: FunctionComponent<DatePickerProps> = props => {
  const { value, onChange } = props;

  const ref = useRef(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const today = new Date();

  useOutsideClick({
    ref: ref,
    handler: onClose,
  });

  const handleChange = useCallback(
    (value: DatePickerValue) => {
      onChange(value);
      onClose();
    },
    [onChange]
  );

  // formatted date should be in the format of "MM/DD/YYYY"
  const dateValueToString = useCallback((date?: DatePickerValue) => {
    // string should be in the format of "MM/DD/YYYY"
    if (date === null || date === undefined) {
      return new Date().toLocaleDateString();
    }
    if (Array.isArray(date)) {
      return date.map(d => d?.toLocaleDateString()).join(' → ');
    }
    return date.toLocaleDateString();
  }, []);
  // return a button that opens a react-calendar calendar popover
  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      gutter={16}
      placement={`bottom-end`}
    >
      <PopoverTrigger>
        <Button className="DatePickerContainer">
          <MdOutlineCalendarMonth size={'1.2rem'} color="inherit" />
          {dateValueToString(value)}
        </Button>
      </PopoverTrigger>
      <PopoverContent bg={'transparent'} border={'none'} color="black">
        <Box className="CalendarContainer" ref={ref}>
          <Calendar
            onChange={handleChange}
            value={value}
            defaultView="month"
            minDetail="year"
            selectRange={true}
            maxDate={today}
          />
        </Box>
      </PopoverContent>
    </Popover>
  );
};
