import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FunctionComponent, ReactNode } from 'react';

export interface DrawerDialogProps {
  cancelTitle?: string;
  submitTitle?: string;
  title: string;
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: () => void;
  size?: string;
}

export const DrawerDialog: FunctionComponent<DrawerDialogProps> = props => {
  return (
    <Drawer
      isOpen={props.isOpen}
      placement="right"
      onClose={props.onClose}
      size={props.size || 'md'}
    >
      <DrawerOverlay />
      <DrawerContent bgColor={'brand_black.50'}>
        <DrawerCloseButton />
        <DrawerHeader>{props.title}</DrawerHeader>
        <DrawerBody>{props.children}</DrawerBody>
        <DrawerFooter gap={5} justifyContent={'center'}>
          <Button
            variant="black"
            onClick={() => {
              props.onClose?.();
            }}
          >
            {props.cancelTitle ?? 'Cancel'}
          </Button>
          {props.onSubmit && (
            <Button
              variant="black"
              onClick={() => {
                props.onSubmit?.();
              }}
            >
              {props.submitTitle ?? 'Accept'}
            </Button>
          )}
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};
