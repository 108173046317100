import { Box, Center, Stack, Text } from '@chakra-ui/react';
import { FunctionComponent, ReactNode } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import './RechartsLine.scss';
import { isEmpty } from 'lodash';

export type LineChartType = 'scans' | 'revenue';

export interface LineChartData {
  color: string;
  id: string;
  xAxisTooltipFormatter?: (value: any) => string;
  yAxisTooltipFormatter?: (value: any) => string;
  data: {
    x: string | number;
    y: string | number;
    metadata?: {
      [key: string]: string | number;
    };
  }[];
}

export interface TooltipPayload {
  color: string;
  dataKey: string;
  fill: string;
  fillOpacity: string;
  formatter: any;
  hide: boolean;
  id: string;
  name: string;
  payload: {
    x: string | number;
    y: string | number;
    metadata?: {
      [key: string]: string | number;
    };
  };
  stroke: string;
  value: string | number;
}

export const LineChartColors: Record<
  LineChartType,
  { line: string; dot: string }
> = {
  revenue: { line: '#1EC5C4', dot: '#EDFB5C' },
  scans: { line: '#EDFB5C', dot: '#1EC5C4' },
};

export interface LineChartProps {
  data: LineChartData[];
  xLabel?: string;
  xAxisTickFormatter?: (value: any) => string;
  yAxisTickFormatter?: (value: any) => string;
  xAxisTooltipFormatter?: (value: any) => string;
  yAxisTooltipFormatter?: (value: any) => string;
  tooltipContent?: JSX.Element;
  yLabel?: string;
  height: number | string;
  allowDecimals?: boolean;
}

const DefaultXAixsTickFormatter = (value: any) => {
  if (typeof value === 'string') {
    switch (value) {
      case 'January':
        return 'Jan';
      case 'February':
        return 'Feb';
      case 'March':
        return 'Mar';
      case 'April':
        return 'Apr';
      case 'May':
        return 'May';
      case 'June':
        return 'Jun';
      case 'July':
        return 'Jul';
      case 'August':
        return 'Aug';
      case 'September':
        return 'Sep';
      case 'October':
        return 'Oct';
      case 'November':
        return 'Nov';
      case 'December':
        return 'Dec';
      default:
        return value;
    }
  }
  if (typeof value === 'number') {
    return value.toLocaleString();
  }
  return value;
};

export const defaultYAxisTickFormatter = (value: number) => {
  return value.toLocaleString();
};

const TooltipContent = (props: any) => {
  const { active, payload, xAxisTooltipFormatter, yAxisTooltipFormatter } =
    props;

  if (active && payload && payload.length) {
    const { x, y } = payload[0].payload;

    return (
      <Box className="CustomTooltip">
        <Box className="TooltipTitle">
          {xAxisTooltipFormatter ? xAxisTooltipFormatter(x) : x}
        </Box>
        <Box className="TooltipValue">
          {yAxisTooltipFormatter ? yAxisTooltipFormatter(y) : y}
        </Box>
      </Box>
    );
  }
  return null;
};

export const LineGraph: FunctionComponent<LineChartProps> = props => {
  const {
    data,
    xLabel,
    yLabel,
    height,
    xAxisTickFormatter,
    yAxisTickFormatter,
    xAxisTooltipFormatter,
    yAxisTooltipFormatter,
    tooltipContent,
    allowDecimals,
  } = props;

  return (
    <Box className="LineChartContainer">
      {isEmpty(data) && (
        <Box h={height} className="EmptyMessage">
          <Center h={'100%'}>{`No data at this time`}</Center>
        </Box>
      )}
      {!isEmpty(data) && (
        <Stack w={'100%'}>
          <ResponsiveContainer width="100%" height={height} minHeight={height}>
            <AreaChart width={600} height={300}>
              <CartesianGrid
                strokeDasharray={'2 2'}
                vertical={false}
                stroke={'#DFE5EE'}
              />
              <YAxis
                label={{
                  value: yLabel || '',
                  angle: -90, // Rotate label to vertical
                  position: 'insideLeft', // Position of the label
                  style: {
                    textAnchor: 'middle', // Center align the text
                    fontSize: 14,
                  },
                }}
                dataKey="y"
                stroke="lightGray"
                width={35}
                tick={{
                  fontSize: 13,
                  fill: 'gray',
                }}
                axisLine={false}
                tickLine={false}
                allowDecimals={allowDecimals}
                tickFormatter={yAxisTickFormatter || defaultYAxisTickFormatter}
              />
              <XAxis
                dataKey="x"
                xAxisId={'1'}
                interval={'preserveStartEnd'}
                allowDuplicatedCategory={false}
                height={24}
                padding={{ left: 14, right: 0 }}
                tickMargin={10}
                tickFormatter={xAxisTickFormatter || DefaultXAixsTickFormatter}
                stroke="lightGray"
                tick={{
                  fontSize: 13,
                  fill: 'gray',
                }}
                tickLine={false}
              />
              <defs>
                {data.map((x, idx) => (
                  <linearGradient
                    id={`colorGradient${idx}`}
                    key={idx}
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="0%" stopColor={x.color} stopOpacity={0.5} />
                    <stop
                      offset="100%"
                      stopColor={x.color}
                      stopOpacity={0.05}
                    />
                  </linearGradient>
                ))}
              </defs>

              {data.map((x, idx) => (
                <Area
                  key={idx}
                  type="linear"
                  dataKey={'y'}
                  id={x.id}
                  xAxisId={'1'}
                  data={x.data}
                  stroke={x.color}
                  fill={`url(#colorGradient${idx})`}
                  stackId={`1`}
                  strokeWidth={2}
                  dot={{
                    fill: x.color,
                    strokeWidth: 0,
                    r: 0,
                  }}
                />
              ))}
              <Tooltip
                content={
                  tooltipContent || (
                    <TooltipContent
                      xAxisTooltipFormatter={xAxisTooltipFormatter}
                      yAxisTooltipFormatter={yAxisTooltipFormatter}
                    />
                  )
                }
              />
            </AreaChart>
          </ResponsiveContainer>
          <Text pl={8} color={'gray'} fontSize={14}>
            {xLabel}
          </Text>
        </Stack>
      )}
    </Box>
  );
};
