import { extendTheme } from '@chakra-ui/react';

const brandColors = {
  brand_pink: {
    50: '#ffecf3',
    100: '#fec5db',
    200: '#fda9ca',
    300: '#fc82b1',
    400: '#fc69a2',
    500: '#fb448b',
  },
  brand_yellow: {
    50: '#fefff1',
    100: '#fbfed5',
    200: '#f9fdc0',
    300: '#f7fca3',
    400: '#f5fc91',
    500: '#f3fb76',
  },
  brand_mint: {
    50: '#f2fffb',
    100: '#d5fff2',
    200: '#c1ffec',
    300: '#a5ffe4',
    400: '#93ffde',
    500: '#78ffd6',
  },
  brand_black: {
    50: '#ebebeb',
    100: '#bfbfbf',
    200: '#a1a1a1',
    300: '#767676',
    400: '#5b5b5b',
    500: '#121213',
  },

};

export const theme = extendTheme({
  shadows: ['none', '0px 2px 4px rgba(0, 0, 0, 0.1)'],
  colors: {
    brand_pink: {
      50: '#ffecf3',
      100: '#fec5db',
      200: '#fda9ca',
      300: '#fc82b1',
      400: '#fc69a2',
      500: '#fb448b',
    },
    brand_yellow: {
      50: '#fefff1',
      100: '#fbfed5',
      200: '#f9fdc0',
      300: '#f7fca3',
      400: '#f5fc91',
      500: '#f3fb76',
    },
    brand_mint: {
      50: '#f2fffb',
      100: '#d5fff2',
      200: '#c1ffec',
      300: '#a5ffe4',
      400: '#93ffde',
      500: '#78ffd6',
    },
    brand_black: {
      50: '#ebebeb',
      100: '#bfbfbf',
      200: '#a1a1a1',
      300: '#767676',
      400: '#5b5b5b',
      500: '#121213',
    },
  },
  components: {
    Button: {
      variants: {
        pink: {
          bg: brandColors.brand_pink[500],
          color: 'white',
          _hover: {
            _disabled: {
              bg: brandColors.brand_pink[500],
            },
          },
        },
        pinkSecondary: {
          bg: brandColors.brand_pink[100],
          color: brandColors.brand_pink[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_pink[100],
            },
          },
        },
        pinkSoft: {
          bg: '#FF9292',
          color: brandColors.brand_black,
          _hover: {
            _disabled: {
              bg: brandColors.brand_pink[100],
            },
          },
        },
        yellow: {
          bg: brandColors.brand_yellow[500],
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_yellow[500],
            },
          },
        },
        yellowSecondary: {
          bg: brandColors.brand_yellow[100],
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_yellow[100],
            },
          },
        },
        mint: {
          bg: brandColors.brand_mint[500],
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_mint[500],
            },
          },
        },
        mintSecondary: {
          bg: brandColors.brand_mint[100],
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_mint[100],
            },
          },
        },
        orange: {
          bg: 'orange',
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: 'orange.100',
            },
          },
        },
        green: {
          bg: '#92FF96',
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: '92FF96.100',
            },
          },
        },
        red: {
          bg: '#a70000',
          color: brandColors.brand_black[500],
          _hover: {
            _disabled: {
              bg: brandColors.brand_black[200],
            },
          },
        },
        black: {
          bg: brandColors.brand_black[500],
          color: 'white',
          _hover: {
            _disabled: {
              bg: brandColors.brand_black[200],
            },
          },
        },
      },
    },
  },
});
