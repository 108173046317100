import axios, { AxiosResponse } from 'axios';
import { configuration } from '../../config/configuration';
import _ from 'lodash';
// import { data } from '../../config/config';
import { User } from '../data/user';
import { AddContractArgs, ContractApiResponse, ContractData, DeployContractArgs, UpdateContractArgs } from '../data/contact-responses';

export class DashboardApiService {
  private accessToken: string;
  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }
  private buildHeaders() {
    return {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    };
  }

  public async inviteUser(email: string, fullName: string, publicName:string) {
    const response = await axios.post(
      configuration.api.apiUrl + '/users',
      {
        fullName: fullName,
        publicName:publicName,
        email: email,
      },
      this.buildHeaders()
    );
    console.log(response)

    console.log("response",response.data)
    return response.data;
  }
  public async updateUser(id: string, publicName: string) {
    const response = await axios.put(
      configuration.api.apiUrl + '/users',
      {
        id: id,
        nickname:publicName,
      },
      this.buildHeaders()
    );
    console.log(response)

    console.log("response",response.data)
    return response.data;
  }
  public async getUsers(): Promise<User[]> {
    const response = await axios.get(
      configuration.api.apiUrl + '/users',
      this.buildHeaders()
    );
    return response.data;
  }

  public async fetchApiKeys(): Promise<string[]> {
    // console.log('fetchApiKeys', configuration.api.apiUrl);
    const response = await axios.get(
      configuration.api.apiUrl + '/api-key',
      this.buildHeaders()
    );
  
   if(response.status === 200 && response.data.success) {
    const result = _.map(
      response.data.data.items,
      x => x.value
    );
    return result;
   }
   return []
  }

  public async createAdminUserKey(
    name: string,
    email?: string
  ): Promise<AxiosResponse> {
   
    const url = configuration.api.apiUrl + '/api-key';
    const data = {
      name,
      email,
      type: configuration.mentaport.adminCustomerType,
    };
   
    const response = await axios.post(url, data, this.buildHeaders());
    console.log(response)
    return response?.data?.final?.data?.apiKeyId;
  }
  public async getDashboardContracts(): Promise<ContractData> {
    const response: AxiosResponse = await axios.get(configuration.api.apiUrl + '/contracts/contracts', this.buildHeaders());
    return response?.data?.data
  }

  public async getDashboardCertificates(): Promise<ContractData> {
    const response: AxiosResponse = await axios.get(configuration.api.apiUrl + '/contracts/certificates', this.buildHeaders());
    return response?.data?.data
  }

  public async deployContract(data: DeployContractArgs) {
    const response = await axios.post(
      configuration.api.apiUrl + '/contracts/deploy',
      data,
      this.buildHeaders()
    );
    console.log(response)
    return response.data;
  }

  public async addContract(data: AddContractArgs) {
    const response = await axios.post(
      configuration.api.apiUrl + '/contracts/add',
      data,
      this.buildHeaders()
    );
    console.log(response)
    return response.data;
  }

  public async updateContract(data: UpdateContractArgs) {
    const response = await axios.put(
      configuration.api.apiUrl + '/contracts/update',
      data,
      this.buildHeaders()
    );
    console.log(response)
    return response.data;
  }
  // Creating API for certificat app user
  // public async createApiKeyCustomer(
  //   name: string,
  //   email?: string
  // ): Promise<AxiosResponse> {
  //   const response = await axios.post(
  //     configuration.api.apiUrl + '/api-key',
  //     { name, email, type: configuration.mentaport.certCustomerType },
  //     this.buildHeaders()
  //   );

  //   return response?.data?.final?.data?.apiKeyId;
  // }
}
