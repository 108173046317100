import {
  Avatar,
  Button,
  Flex,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import { ManagementPage } from '../../components/commons/ManagementPage/ManagementPage';
import { useDialog } from '../../hooks/use-dialog';
import { MessageStatus } from '../../services/dialog';
import { useNavigate } from 'react-router-dom';
import { DrawerDialog } from '../../components/commons/DrawerDialog/DrawerDialog';
import { CreateInput } from '../../components/commons/CreateInput/CreateInput';
import { useCallback, useMemo, useState } from 'react';
import { useDashboardApi } from '../../hooks/use-dashboard-api';
import { getPlaceHolder } from '../../helpers/list-helper';
import { ShortcutCopy } from '../../components/commons/ShortCutCopy/ShortCutCopy'
import { useAtomUsers } from '../../services/jotai-store-service'
import {User} from '../../data/user';
import { DataTable, TableColumn } from '../../components/commons/Table/Table';
import { CustomCellRendererProps } from 'ag-grid-react';
import "./UsersPage.scss";
import { AnalyticEvent, useAnalytics } from '../../hooks/use-analytics';

export const UsersPage = () => {
  const dialog = useDialog();
  const dashboardService = useDashboardApi();
  const [inviteIsLoading, setInviteIsLoading] = useState<boolean>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const [fisrtName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [publicName, setPublicName] = useState<string>();

  const [email, setEmail] = useState<string>();
  // const [triggerRefresh, setTriggerRefresh] = useState<boolean>(true);

  const [users, loadingUsers] = useAtomUsers();

  
  const handleUpdate = async(id:string) => {
    // const result = await dashboardService?.updateUser(id, "Mentaport");
  };
  const handleCreateNotImplementedFeature = () => {
    dialog.notify(
      MessageStatus.Info,
      'Feature',
      'This feature will be implemented shortly'
    );
  };

  const handelInvitation = async () => {
    if (!email || !fisrtName || !lastName || !publicName) {
      return;
    }
    try {
      const fullName = fisrtName + ' ' + lastName;
      setInviteIsLoading(true);
      const result = await dashboardService?.inviteUser(email, fullName, publicName);
      if (result?.error) {
        dialog.notify(MessageStatus.Error, 'Error', result?.data.error);
        return;
      }
      if (!result.status) {
        dialog.notify(MessageStatus.Error, 'Error', result?.data.msg);
        return;
      }
      dialog.notify(
        MessageStatus.Success,
        'User Invitation',
        'The invite was sent successfully!'
      );
      onClose();
      // console.log('add user res',result.data.data)
      const user_n = result.data.data;
      trackEvent(AnalyticEvent.NavigationToUserContracts, {
        userId: user_n.customerId,
      });
       navigate(`contracts/${user_n.customerId}/${user_n.email}`);
      // setTriggerRefresh(!triggerRefresh);
      // redirect to user page:
    } catch (error) {
      dialog.notify(
        MessageStatus.Error,
        'Error',
        'The invitation process failed!'
      );
    } finally {
      setEmail('');
      setFirstName('');
      setLastName('');
      setPublicName('');
      setInviteIsLoading(false);
    }
  };

  const getManagementButtons = () => {
    return [
      <Button
        marginLeft={10}
        variant="pink"
        size="sm"
        onClick={() => {
          onOpen();
        }}
      >
        Invite User
      </Button>,
    ];
  };

  const renderSideDrawer = () => {
    return (
      <DrawerDialog
        key="side-drawer"
        isOpen={isOpen}
        onClose={() => {
          onClose();
        }}
        title={'Invite user'}
      >
        <Flex className="UserInviteContainer" gap={5} direction={'column'}>
          <Flex className="UserInviteContainer" direction={'column'}>
            <CreateInput
              isDisabled={false}
              title="Email *"
              value={email ?? ''}
              onChange={value => setEmail(value)}
            />
          </Flex>
          <Flex className="UserInviteContainer" direction={'column'}>
            <CreateInput
              isDisabled={false}
              title="First Name *"
              value={fisrtName ?? ''}
              onChange={value => setFirstName(value)}
            />
          </Flex>
          <Flex className="UserInviteContainer" direction={'column'}>
            <CreateInput
              isDisabled={false}
              title="Last Name *"
              value={lastName ?? ''}
              onChange={value => setLastName(value)}
            />
          </Flex>
          <Flex className="UserInviteContainer" direction={'column'}>
            <CreateInput
              isDisabled={false}
              title="Public User Name *"
              value={publicName ?? ''}
              onChange={value => setPublicName(value)}
            />
          </Flex>
          <Button
            isDisabled={!email || !fisrtName || !lastName}
            variant={'pinkSoft'}
            isLoading={inviteIsLoading}
            onClick={() => handelInvitation()}
          >
            Invite
          </Button>
        </Flex>
      </DrawerDialog>
    );
  };

  const CustomUserCellRenderer: React.FC<CustomCellRendererProps> = prop => {
    const user = prop.data as User;
    const onOpenContract = useCallback(() => {
      trackEvent(AnalyticEvent.NavigationToUserContracts, {
        userId: user.customerId,
      });
      navigate(`contracts/${user.customerId}/${user.email}`);
    }, [user]);
    const onResetApi = useCallback(() => {
      handleCreateNotImplementedFeature();
    }, []);

    return (
      <HStack h={"100%"}>
        <Button
          borderRadius={8}
          onClick={() => {
            onOpenContract();
          }}
        >
          Open Contract
        </Button>
        <Button
          variant="pinkSoft"
          isDisabled={true}
          onClick={() => {
            onResetApi();
          }}
        >
          Reset API
        </Button>
      </HStack>
    )
  }


  const tableColumns: TableColumn[] = useMemo(() => {
    const tableColumns: TableColumn[] = [];
    if (users?.length) {
      Object.keys(users[0]).forEach(key => {

        tableColumns.push({
          title: key,
          id: key,
        });
      });

      tableColumns.push({
        title: 'Actions',
        id: 'actions',
        customRenderer: CustomUserCellRenderer,
        width: 280,
      });
    }
    return tableColumns;
  }, [users])

  return (
    <ManagementPage
      title={'Users'}
      path={'users'}
      isLoading={loadingUsers}
      previousLocation={''}
      placeholder={getPlaceHolder()}
      buttons={getManagementButtons()}
    >
      {renderSideDrawer()}
      <div className="UsersPage">
        <DataTable columns={tableColumns} data={users} rowHeight={60} height={500} getRowStyle={() => ({
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
        })}/>
      </div>
    </ManagementPage>
  );
};

