import { Auth0Provider, AppState } from "@auth0/auth0-react";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { configuration } from './config/configuration';

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithNavigate = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const navigate = useNavigate();
  // console.log(configuration.dashboardUserRegistry)
  const domain = configuration.dashboardUserRegistry.authDomain;
  const clientId = configuration.dashboardUserRegistry.clientId;
  // const redirectUri = window.location.origin;
  const audience = configuration.dashboardUserRegistry.audience;
  const redirectUri = `${configuration.dashboardUserRegistry.baseUrl}/login-callback`;

  const onRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname);
    // navigate(redirectUri)
  };

  if (!(domain && clientId && redirectUri && audience)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience: audience,
        redirect_uri: redirectUri,
      }}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
};
